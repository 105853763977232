import React, { useEffect } from 'react'
import { Platform } from 'react-native'

import Dialog, { DialogProps as DProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import CloseIcon from 'app/assets/icons/close-icon.svg'
import fonts from 'app/theme/common/fonts'

import IconButton from '../IconButton'

import type { DialogCommonProps } from './Dialog.types'

type CustomDialogProps = {
  dialogSx?: DProps['sx']
  titleSx?: DProps['sx']
  actionsSx?: DProps['sx']
  contentSx?: DProps['sx']
  showCloseIcon?: boolean
  disableScroll?: boolean
  parentRef?: React.RefObject<HTMLDivElement>
}

export type DialogProps = Omit<DProps, 'title'> &
  DialogCommonProps &
  CustomDialogProps

const ScrollDialog: RFC<DialogProps> = ({
  title,
  open,
  onClose,
  actions,
  dividers,
  children,
  dialogSx,
  titleSx,
  contentSx,
  actionsSx,
  showCloseIcon = true,
  parentRef,
  ...rest
}) => {
  const descriptionElementRef = React.useRef<HTMLElement>(null)

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <Dialog
      ref={parentRef}
      sx={{
        '& .MuiDialog-paper': {
          py: 5,
          px: { xs: 2, sm: 11 },
          m: { xs: 2, md: 4 },
          width: '100%',
          maxWidth: '527px',
          borderRadius: 2,

          '& .MuiDialogContent-root': {
            borderBottom: 'none'
          }
        },
        ...dialogSx
      }}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll={'paper'}
      {...rest}
    >
      <DialogTitle
        sx={{
          p: 0,
          mb: '19px',
          fontWeight: 400,
          fontFamily: fonts.nunito.name,
          fontSize: 30,
          lineHeight: '35px',
          textAlign: 'center',
          ...titleSx
        }}
      >
        {title}

        {showCloseIcon && (
          <IconButton
            {...Platform.select({ web: { onClick: onClose } })}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '8px'
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent
        dividers={dividers}
        sx={{
          p: 0,
          mb: 3,
          ...contentSx
        }}
      >
        <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
          {children}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ p: 0, ...actionsSx }}>{actions}</DialogActions>
    </Dialog>
  )
}

export default ScrollDialog
