import { withSearch } from '@elastic/react-search-ui'
import { SearchContextState } from '@elastic/react-search-ui/lib/esm/withSearch'

import { ButtonProps } from 'app/components/base/Button'
import MyGarageButton from 'app/components/buttons/MyGarageButton'
import {
  transformQueryParamsToSearchParams,
  updateSearchParams
} from 'app/utils/helpers/elastic.helpers'

type ContainerContext = Pick<
  SearchContextState,
  'filters' | 'setFilter' | 'removeFilter'
>

type ContainerProps = { sx?: ButtonProps['sx'] }

type MyGarageWithSearchProps = ContainerProps & ContainerContext & {}

const selector = ({
  setFilter,
  filters,
  removeFilter
}: SearchContextState) => ({
  setFilter,
  filters,
  removeFilter
})

const MyGarageWithSearch = withSearch<ContainerProps, ContainerContext>(
  selector
)(({ setFilter, filters, removeFilter, ...props }: MyGarageWithSearchProps) => {
  const onSelect = ({
    make,
    model,
    generation,
    serie,
    trim
  }: Omit<VehicleModel, 'model'> & {
    model: string
  }) => {
    const { filters: vehicleFilters } = transformQueryParamsToSearchParams({
      makes: make!,
      models: model,
      generation_id: generation?.id as string,
      serie_id: serie?.id as string,
      trim_id: trim?.id as string
    })

    const setQueryFilters = updateSearchParams({
      setFilter,
      removeFilter,
      filters
    })

    setQueryFilters({ filters: vehicleFilters })
  }

  return <MyGarageButton onConfirm={onSelect} sx={props.sx} />
})

export default MyGarageWithSearch
