import { Image as NativeImage, Platform } from 'react-native'

import { FormattedMessage } from 'react-intl'

import rimsSrc from 'app/assets/images/rims.png'
import Box from 'app/components/base/Box/Box'
import Image from 'app/components/base/Image'
import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'

import CheckContainer from './CheckContainer'

const CheckVideo = () => {
  const isWeb = Platform.OS === 'web'

  return (
    <CheckContainer>
      <Stack
        sx={{
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <Text
          variant="h1"
          color="common.white"
          sx={{
            fontWeight: { xs: 700, md: 400 },
            ...Platform.select({
              web: {
                lineHeight: {
                  xs: '58px',
                  md: '65px'
                }
              },
              native: { lineHeight: 58 }
            }),
            zIndex: 1,
            textAlign: 'center',
            mx: 3
          }}
        >
          <FormattedMessage defaultMessage="Explore our video & blogs" />
        </Text>

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            ...Platform.select({
              web: {
                maxWidth: 350,
                '& > span': {
                  width: '100% !important',
                  height: '100% !important'
                }
              },
              native: {
                display: 'flex',
                alignItems: 'center'
              }
            })
          }}
        >
          {isWeb ? (
            <Image
              src={rimsSrc}
              layout="responsive"
              alt="rims"
              sizes="640px"
              height={254}
              width={350}
            />
          ) : (
            <NativeImage
              source={require('app/assets/images/rims.png')}
              style={{
                resizeMode: 'cover',
                width: 350,
                height: 254
              }}
            />
          )}
        </Box>
      </Stack>
    </CheckContainer>
  )
}

export default CheckVideo
