import React from 'react'
import { Platform } from 'react-native'

import HeartActiveIcon from 'app/assets/icons/listing/small-heart-active.svg'
import HeartIcon from 'app/assets/icons/listing/small-heart.svg'
import UseLikeListing from 'app/hooks/useLikeListing'
import useAuthStore, { getAuthUserId } from 'app/store/auth.store'

import IconButton, { IconButtonProps } from '../base/IconButton'

type LikeButtonProps = Partial<IconButtonProps> & {
  listingId: string
  listingUserId?: string
  isLiked?: boolean
}

const LikeButton = ({
  listingId,
  listingUserId,
  isLiked = false,
  ...props
}: LikeButtonProps) => {
  const { liked, toggleLike, isLoading } = UseLikeListing({
    listingId,
    isLiked
  })
  const userId = useAuthStore(getAuthUserId)
  const isOwner = userId === listingUserId

  return (
    <>
      {!isOwner && (
        <IconButton
          aria-label="like"
          disabled={isLoading}
          {...props}
          {...Platform.select({
            web: {
              onClick: (e) => {
                e.stopPropagation()
                toggleLike()
              },
              disableRipple: true
            },
            native: {
              onClick: toggleLike
            }
          })}
        >
          {liked ? <HeartActiveIcon /> : <HeartIcon />}
        </IconButton>
      )}
    </>
  )
}

export default LikeButton
