import React, { useMemo } from 'react'

import SliderRange, { SliderRangeProps } from 'app/components/base/SliderRange'
import { isOfType } from 'app/utils/helpers/type.helpers'

import withFacet, { FacetComponentProps } from '../components/withFacet'

export type SliderRangeFilterProps = Omit<
  SliderRangeProps,
  'value' | 'onChange'
>

const SliderRangeFilter = ({
  filter: { values, onChange, onRemove },
  min,
  max,
  ...props
}: SliderRangeFilterProps & FacetComponentProps) => {
  const value = useMemo(() => {
    const firstValue = values[0]
    const isRange =
      !isOfType.string(firstValue) && (!!firstValue?.from || !!firstValue?.to)
    if (isRange) {
      const { from, to } = firstValue
      return [+from, +to] as const
    }

    return null
  }, [values])

  return (
    <SliderRange
      value={value}
      onChange={([from, to] = [0, 0]) => {
        if (from === min && to === max) {
          onRemove(undefined)
        } else {
          onChange({ from, to })
        }
      }}
      min={min}
      max={max}
      {...props}
    />
  )
}

export default withFacet<SliderRangeFilterProps>(SliderRangeFilter)
