import { Platform } from 'react-native'

import PlaySVG from 'app/assets/icons/homePage/play.svg'
import Box from 'app/components/base/Box'
import IconButton from 'app/components/base/IconButton'
import Image from 'app/components/base/Image'
import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'
import useOpenExternalLink from 'app/hooks/useOpenExternalLink'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'

import GradientFilter from './GradientFilter'

interface VideoItemProps {
  title: string
  image: string
  link: string
  index: number
}

const VideoItem: RFC<VideoItemProps> = ({ title, image, link, index }) => {
  const { openLink } = useOpenExternalLink()

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '16px',
        width: '100%',
        minHeight: 225,
        ...Platform.select({
          web: {
            gridArea: `video${index}`
          }
        })
      }}
    >
      <Image src={image} alt={title} layout="fill" sizes="640px" />

      <GradientFilter />

      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          px: 3,
          py: 2
        }}
      >
        <Text
          variant="h4"
          color="common.white"
          lineClamp={3}
          sx={{
            fontWeight: 700,
            zIndex: 1,
            ...Platform.select({
              web: {
                wordBreak: 'break-word',
                maxWidth: '100%'
              },
              native: {
                maxWidth: 220
              }
            })
          }}
        >
          {title}
        </Text>

        <IconButton
          aria-label="open video"
          sx={{
            height: 36,
            width: 36,
            mb: 0.5,
            ml: 0,
            zIndex: 1,
            backgroundColor: 'secondary.main',
            ...Platform.select({
              web: {
                '&:hover': {
                  backgroundColor: 'secondary.dark'
                }
              }
            })
          }}
          onClick={() =>
            openLink({ link, ...(IS_NATIVE_APP && { withoutWebView: true }) })
          }
        >
          <PlaySVG />
        </IconButton>
      </Stack>
    </Box>
  )
}

export default VideoItem
