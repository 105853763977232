import React, { useState, useEffect } from 'react'

import { SearchContext } from '@elastic/react-search-ui'
import { SearchDriver } from '@elastic/search-ui'
import type { SearchDriverOptions } from '@elastic/search-ui'

export interface SearchProviderContextInterface {
  driver: SearchDriver
}

type SearchProviderProps = {
  children: React.ReactNode
  config: SearchDriverOptions
  driver?: SearchDriver
}

const ElasticSearchProvider = ({
  children,
  config
}: SearchProviderProps): JSX.Element => {
  const [driverInstance, setDriverInstance] = useState<SearchDriver>(() => {
    return new SearchDriver({
      ...config,
      a11yNotificationMessages: {
        ...config.a11yNotificationMessages
      }
    })
  })

  useEffect(() => {
    if (driverInstance && config?.searchQuery) {
      driverInstance.setSearchQuery(config?.searchQuery)
    }
  }, [config.searchQuery])

  useEffect(() => {
    if (driverInstance && config?.autocompleteQuery) {
      driverInstance.setAutocompleteQuery(config.autocompleteQuery)
    }
  }, [config.autocompleteQuery])

  const contextValue: SearchProviderContextInterface = {
    driver: driverInstance
  }

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  )
}

export default ElasticSearchProvider
