import { useEffect } from 'react'

import { useIntl } from 'react-intl'

import useVerifyAuth from 'app/features/ListingFeature/hooks/useVerifyAuth'
import { generateSaveSearchesDto } from 'app/features/Profile/SavedSearchFeature/helpers/save-search.helpers'
import useBoolean from 'app/hooks/useBoolean'
import useCurrencyState from 'app/hooks/useCurrencyState'
import useDebounceValue from 'app/hooks/useDebounceValue'
import Alert from 'app/services/Alert'
import ErrorHandler, { isErrorHandlerType } from 'app/services/ErrorHandler'
import { useCreateSavedSearches } from 'app/services/Query/saved-search/saved-search.mutation'
import { useGetSavedSearchByFilters } from 'app/services/Query/saved-search/saved-search.query'
import useAuthStore, { getIsConfirmed } from 'app/store/auth.store'

const useSavedSearch = ({ searchParams }) => {
  const intl = useIntl()
  const checkAuth = useVerifyAuth()
  const { currency } = useCurrencyState()

  const isAuth = useAuthStore(getIsConfirmed)

  const [isSavedSearch, setSavedSearch] = useBoolean(false)

  const { q, ...restParams } = searchParams
  const debounceQuery = useDebounceValue(q)
  const paramsDto = generateSaveSearchesDto({
    ...restParams,
    q: debounceQuery
  })

  const emptyDto = Object.keys(paramsDto).length === 0
  const enabled = !emptyDto && isAuth

  const { refetch } = useGetSavedSearchByFilters({
    params: paramsDto,
    options: {
      enabled,
      onSuccess: (data) => {
        if (data?.id) {
          setSavedSearch.on()
        } else {
          setSavedSearch.off()
        }
      }
    }
  })

  const { mutateAsync, isLoading } = useCreateSavedSearches({
    onSuccess: () => {
      setSavedSearch.on()

      Alert.success(
        intl.formatMessage({
          defaultMessage: 'Successfully saved'
        })
      )
    }
  })

  const handleCreateSavedSearches = async () => {
    try {
      if (emptyDto) {
        Alert.error(
          intl.formatMessage({
            defaultMessage: "You don't have any filters selected"
          })
        )
        return
      }

      await mutateAsync(paramsDto)
    } catch (error) {
      if (isErrorHandlerType(error)) {
        const customError = 'message' in error ? error.message : ''

        new ErrorHandler(error.data || customError)
        console.error(error)
      }
    }
  }

  const onSavedSearch = () => {
    checkAuth(handleCreateSavedSearches)
  }

  useEffect(() => {
    if (enabled) {
      refetch()
    }
  }, [enabled, currency])

  return { isSavedSearch, isLoading, onSavedSearch }
}

export default useSavedSearch
