import React from 'react'

import Box, { BoxProps } from '../Box'

export type FlexProps = BoxProps & {
  pointerEvents?: string
}

const Flex = ({ sx, children, ...props }: FlexProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', ...sx }} {...props}>
      {children}
    </Box>
  )
}

export default Flex
