import { getVehicleGenerations } from 'app/services/Api/listing'

export interface TransformedGenerationOptions {
  id: string | number
  name: string
  simpleName: string
  groupBy: string
  modelId: string
}

export const transformGenerationOptions = async (
  models: ListingCarAttributeId[]
): Promise<TransformedGenerationOptions[]> => {
  const resp = await getVehicleGenerations(models)

  const generateOptions = resp?.map((option) => {
    const { year_begin, year_end, name = '', id, make, model } = option || {}
    const years = year_begin && `[${year_begin || ''} - ${year_end || ''}]`

    return {
      id,
      name: `${name} ${years || ''}`,
      simpleName: name,
      groupBy: `${make?.name}・${model?.name}`,
      modelId: model?.id
    }
  })

  return generateOptions
}

export const formatGroupByOption = (
  option: ListingSeriesModel | ListingTrimModel
) => {
  const { make, model, generation } = option || {}
  const { year_begin = '', year_end = '', name } = generation ?? {}
  const years = year_begin ? `[${year_begin} - ${year_end}]` : name

  if (!years) return `${make?.name}・${model?.name}`

  if ('serie' in option && !!option.serie?.name)
    return `${make?.name}・${model?.name}・${years}・${option.serie?.name}`

  return `${make?.name}・${model?.name}・${years}`
}
