import { Platform } from 'react-native'

import Stack from 'app/components/base/Stack'
import { useGetHomePageVideos } from 'app/services/Query/user/user.query'

import CheckVideo from './components/CheckVideo'
import VideoItem from './components/VideoItem'

const Videos: RFC = () => {
  const enabledVideos = Platform.select({
    web: false,
    native: true
  })

  const { data: videos } = useGetHomePageVideos({
    options: {
      enabled: enabledVideos
    }
  })

  return (
    <Stack
      {...Platform.select({
        native: {
          spacing: 2
        }
      })}
      sx={{
        ...Platform.select({
          web: {
            display: { xs: 'flex', md: 'grid' },
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateAreas: `"video0 check video1"
                                "video2 check video3"`,
            gap: '30px'
          }
        })
      }}
    >
      <CheckVideo />

      {videos?.slice(0, 4).map((item, index) => (
        <VideoItem
          key={item?.id}
          image={item?.image_url}
          title={item?.title}
          link={item?.link}
          index={index}
        />
      ))}
    </Stack>
  )
}

export default Videos
