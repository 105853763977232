import { Platform } from 'react-native'

import { useIntl } from 'react-intl'

import useVerifyAuth from 'app/features/ListingFeature/hooks/useVerifyAuth'
import Alert from 'app/services/Alert'
import ErrorHandler, { isErrorHandlerType } from 'app/services/ErrorHandler'
import {
  useCreateLikedListingMutation,
  useDeleteLikedListingMutation
} from 'app/services/Query/listing/listing.mutation'
import { hasStatusError } from 'app/utils/helpers/type.helpers'

import useToggleRequest from './useToggleRequest'

interface UseLikeListingProps {
  listingId: string
  isLiked: boolean
}

const useLikeListing = ({ listingId, isLiked }: UseLikeListingProps) => {
  const intl = useIntl()
  const checkAuth = useVerifyAuth()
  const { mutateAsync: createLike } = useCreateLikedListingMutation()
  const { mutateAsync: deleteLike } = useDeleteLikedListingMutation()

  const isWeb = Platform.OS === 'web'

  const {
    state: liked,
    toggle,
    isLoading
  } = useToggleRequest({
    state: isLiked,
    onToggleRequest: () =>
      isLiked ? deleteLike(listingId) : createLike(listingId),
    onSuccess: () => {
      if (isWeb) {
        Alert.success(
          isLiked
            ? intl.formatMessage({ defaultMessage: 'Your Like was deleted' })
            : intl.formatMessage({ defaultMessage: 'Your Like was added' })
        )
      }
    },
    onError: (error) => {
      const errorCodes = [401, 403, 419]

      if (hasStatusError(error)) {
        if (errorCodes.includes(error?.status)) {
          checkAuth()
        } else {
          if (isErrorHandlerType(error)) new ErrorHandler(error?.data)
        }
      }
    }
  })

  return { liked, isLoading, toggleLike: toggle }
}

export default useLikeListing
