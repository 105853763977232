import Box from 'app/components/base/Box'

const CheckContainer: RFC = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: { xs: '100%', md: 350 },
        height: { xs: 285, md: 478 },
        overflow: 'hidden',
        pt: { xs: 3, md: 6 },
        px: 0,
        borderRadius: '16px',

        background: 'linear-gradient(131.51deg, #17D3FF 9.97%, #00EA90 88.46%)',
        gridArea: 'check'
      }}
    >
      {children}
    </Box>
  )
}

export default CheckContainer
