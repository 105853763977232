import { useRouter } from 'solito/router'

import useAuthStore, { getIsConfirmed } from 'app/store/auth.store'
import useSignInPopupStore from 'app/store/signInPopup.store'

const useVerifyAuth = () => {
  const { push } = useRouter()
  const { showPopup } = useSignInPopupStore()

  const isAuth = useAuthStore(getIsConfirmed)

  const verifyAuthWithRedirect = (callback?: (...args: unknown[]) => void) => {
    if (isAuth) {
      if (callback) callback()
    } else {
      if (showPopup) {
        showPopup()
      } else {
        push('/sign-in')
      }
    }
  }

  return verifyAuthWithRedirect
}

export default useVerifyAuth
