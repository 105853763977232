import { useMemo } from 'react'

import { withSearch } from '@elastic/react-search-ui'
import type { SearchContextState } from '@elastic/react-search-ui/lib/cjs/withSearch'
import { helpers } from '@elastic/search-ui'

import { isOfType } from 'app/utils/helpers/type.helpers'

const { markSelectedFacetValuesFromFilters, findFilterValues } = helpers

type FacetContainerContext = Pick<
  SearchContextState,
  'filters' | 'facets' | 'addFilter' | 'removeFilter' | 'setFilter'
>

export type FacetContainerProps = {
  field: string
  dependentField?: string
  filterType?: 'any' | 'all'
  withOptions?: boolean
}

export type FacetComponentProps = {
  filter: {
    options: { selected: boolean; value: any; count: number }[]
    values: string[] | { from: number; to: number }[]
    onRemove: (value: any) => void
    onChange: (value: any) => void
    onSelect: (value: any) => void
    field: string
  }
}

type FacetProps = FacetContainerProps & FacetContainerContext & {}

const withFacet = <T,>(Component: RFC<T & FacetComponentProps>) =>
  withSearch<FacetContainerProps & T, FacetContainerContext>(
    ({ filters, facets, addFilter, removeFilter, setFilter }) => ({
      filters,
      facets,
      addFilter,
      removeFilter,
      setFilter
    })
  )(
    ({
      field,
      dependentField,
      filterType = 'any',
      addFilter,
      removeFilter,
      setFilter,
      facets,
      filters,
      withOptions,
      ...props
    }: FacetProps & T) => {
      const options = useMemo(() => {
        if (!withOptions) return []

        const facetsForField = facets[field]

        const facet = facetsForField?.[0]

        const options = facet
          ? markSelectedFacetValuesFromFilters(
              facet,
              filters || [],
              field,
              filterType
            ).data
          : []

        return options
      }, [withOptions, field, filters, filterType, facets])

      const values = findFilterValues(filters || [], field, filterType)

      const onRemoveDependentField = () => {
        if (!dependentField) return

        const dependentValues = findFilterValues(
          filters || [],
          dependentField,
          filterType
        )

        if (dependentValues?.length) {
          removeFilter(dependentField, undefined, filterType)
        }
      }

      const onRemove = (value) => {
        removeFilter(field, value, filterType)
      }

      const onChange = (value) => {
        setFilter(field, value, filterType)
        onRemoveDependentField()
      }

      const onSelectBase = (value) => {
        addFilter(field, value, filterType)
      }

      const onSelect = (value: string[] | string) => {
        if (isOfType.array(value)) {
          const diff = values
            .filter((x) => !value.includes(isOfType.string(x) ? x : ''))
            .concat(value.filter((x) => !values.includes(x)))
          const newValue = diff[0]

          const isChecked = values.includes(newValue || '')

          if (isChecked) onRemove(newValue)
          else onSelectBase(newValue)
        } else {
          const isChecked = values.includes(value)

          if (!value) onRemove(values[0])
          else if (isChecked) onRemove(value)
          else onChange(value)
        }
      }

      return (
        <Component
          {...(props as any)}
          {...{
            filter: { options, values, onRemove, onChange, onSelect, field }
          }}
        />
      )
    }
  )

export default withFacet
