import dynamic from 'next/dynamic'
import React, { useEffect, useState } from 'react'

import { withSearch } from '@elastic/react-search-ui'
import { SearchContextState } from '@elastic/react-search-ui/lib/cjs/withSearch'
import { FormattedMessage } from 'react-intl'

import { Grid } from '@mui/material'

import RefineResultsIcon from 'app/assets/icons/homePage/refine-results.svg'
import LogoIcon from 'app/assets/icons/logo.svg'
import Button from 'app/components/base/Button'
import SearchBox from 'app/features/SearchFeature/components/SearchBox'
import useBoolean from 'app/hooks/useBoolean'

const FilterDialog = dynamic(() => import('./FilterDialog'), {
  ssr: false
})

type ButtonFindProps = {
  hideModal: () => void
}

type DependentFilterContext = Pick<
  SearchContextState,
  'filters' | 'sortField' | 'searchTerm'
>

const FilterPanelMobile = () => {
  const [isOpenModal, modal] = useBoolean()

  return (
    <>
      <Grid
        spacing={1.5}
        container
        sx={{ display: { xs: 'flex', md: 'none' } }}
      >
        <Grid item xs={6}>
          <SearchBox />
        </Grid>
        <Grid item xs={6}>
          <ButtonFind hideModal={modal.on} />
        </Grid>
      </Grid>
      {isOpenModal && (
        <FilterDialog
          title={'Filters'}
          open={isOpenModal}
          onClose={modal.off}
        />
      )}
    </>
  )
}

const ButtonFind = withSearch<ButtonFindProps, DependentFilterContext>(
  ({ filters, sortField, searchTerm }) => ({ filters, sortField, searchTerm })
)(({ filters, hideModal, sortField }) => {
  const [isFilters, setIsFilters] = useState(false)

  useEffect(() => {
    setIsFilters(!!filters.length || !!sortField)
  }, [filters, sortField])

  return (
    <>
      {isFilters ? (
        <Button
          sx={{ p: 0, px: 1.5 }}
          size="large"
          onClick={hideModal}
          startIcon={<RefineResultsIcon />}
        >
          <FormattedMessage defaultMessage={'Refine Results'} />
        </Button>
      ) : (
        <Button
          sx={{ p: 0, px: 1.5 }}
          size="large"
          onClick={hideModal}
          startIcon={<LogoIcon />}
        >
          <FormattedMessage defaultMessage={'Find My Part'} />
        </Button>
      )}
    </>
  )
})

export default FilterPanelMobile
