import React from 'react'

import {
  ToggleButtonGroup as MuiToggleGroup,
  ToggleButtonGroupProps as MuiToggleGroupProps
} from '@mui/material'

export type ToggleGroupProps = Omit<
  MuiToggleGroupProps,
  'onChange' | 'unselectable'
> & {
  onChange: (value) => void
  unselectable?: boolean
}

const ToggleGroup = ({
  value,
  onChange,
  children,
  defaultValue,
  unselectable,
  ...props
}: ToggleGroupProps) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    if (!unselectable && !value) return

    onChange(value)
  }

  return (
    <MuiToggleGroup
      exclusive
      value={value}
      onChange={handleChange}
      {...props}
      sx={{
        '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          borderTop: '1px solid #D6DCE9'
        },
        ...props.sx
      }}
    >
      {children}
    </MuiToggleGroup>
  )
}

export default ToggleGroup
