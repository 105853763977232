import React from 'react'

import { PopupState } from 'material-ui-popup-state/core'
import { bindPopover } from 'material-ui-popup-state/hooks'

import {
  Fade,
  Paper,
  Popper as MuiPopper,
  PopperProps as MuiPopperProps
} from '@mui/material'

import Box from 'app/components/base/Box'
import Text from 'app/components/base/Text'

interface PopperProps {
  text: string
  placement?: MuiPopperProps['placement']
  popupState: PopupState
}

const Popper: RFC<PopperProps> = ({ text, placement = 'top', popupState }) => {
  const isBottom = placement === 'bottom'

  return (
    <MuiPopper
      {...bindPopover(popupState)}
      transition
      placement={placement}
      sx={{
        zIndex: 10
      }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box>
            <Paper
              sx={{
                p: 1,
                backgroundColor: 'text.light',
                color: 'background.default'
              }}
            >
              <Text variant="subtitle2">{text}</Text>
            </Paper>

            <Box
              width="10px"
              height="10px"
              sx={{
                position: 'absolute',
                left: 'calc(50% - 5px)',
                bottom: -5,
                backgroundColor: 'text.light',
                borderTop: 1,
                borderLeft: 1,
                zIndex: 1,
                transform: 'rotate(45deg)',
                borderColor: 'text.light',
                ...(isBottom && {
                  bottom: 0,
                  top: -5
                })
              }}
            />
          </Box>
        </Fade>
      )}
    </MuiPopper>
  )
}

export default Popper
