import {
  ReactElement,
  ReactNode,
  ReactText,
  Ref,
  forwardRef,
  memo
} from 'react'
import { Platform } from 'react-native'

import FormControl, { FormControlProps } from 'app/components/base/FormControl'
import TextInput, { TextInputProps } from 'app/components/base/TextInput'
import useFormField, { UseFormFieldProps } from 'app/hooks/useFormField'
import { combineControllerProps } from 'app/utils/helpers/form.helpers'

export interface TextFieldCustomProps extends UseFormFieldProps {
  helperText?: ReactText | ReactElement | ReactNode | null
  formControlProps?: FormControlProps
}

export type TextFieldProps = TextFieldCustomProps & TextInputProps

const TextField = (
  { id, helperText, label, formControlProps, ...props }: TextFieldProps,
  ref: Ref<unknown>
) => {
  const { controllerProps, ...rest } = combineControllerProps(props)
  const { field, error, onChange, value } = useFormField(controllerProps)
  return (
    <FormControl
      errorMessage={error}
      helperText={helperText}
      {...formControlProps}
    >
      <TextInput
        {...field}
        ref={ref}
        error={!!error}
        label={label}
        value={value}
        {...Platform.select({
          web: { onChange },
          native: { onChangeText: onChange }
        })}
        {...rest}
      />
    </FormControl>
  )
}

export default memo(forwardRef(TextField))
