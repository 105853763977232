import React from 'react'

import { WithSearch } from '@elastic/react-search-ui'

import { ButtonProps } from 'app/components/base/Button'
import ClearAllButton from 'app/components/buttons/ClearAllButton'

type ClearAllFilterButtonProps = Omit<ButtonProps, 'children'> & {}

const searchSelector = ({
  clearFilters,
  setSearchTerm,
  filters,
  sortField,
  searchTerm,
  setSort,
  reset
}) => ({
  clearFilters,
  setSearchTerm,
  filters,
  sortField,
  searchTerm,
  setSort,
  reset
})

const ClearAllFilterButton = (props: ClearAllFilterButtonProps) => (
  <WithSearch mapContextToProps={searchSelector}>
    {({
      clearFilters,
      setSearchTerm,
      filters,
      sortField,
      searchTerm,
      setSort
    }) => {
      const isFilters = !!filters.length || !!sortField || !!searchTerm

      return (
        <ClearAllButton
          isFilters={isFilters}
          onClick={() => {
            clearFilters()
            setSearchTerm('')
            setSort('', '')
          }}
          {...props}
        />
      )
    }}
  </WithSearch>
)

export default ClearAllFilterButton
