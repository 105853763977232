import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions
} from 'react-query'

import {
  getSavedSearchByFilters,
  getSavedSearches,
  getSavedSearchesListingsCounter
} from 'app/services/Api/savedSearch'
import Deserializer from 'app/services/Deserializer'
import { isOfType } from 'app/utils/helpers/type.helpers'

import { savedSearchKey } from './saved-search.key'

export const savedSearchConfig = {
  getSavedSearches: {
    key: savedSearchKey.getSavedSearches,
    request: async ({ queryKey }) => {
      const [_key, _, params] = queryKey

      const res = await getSavedSearches(params)

      const savedSearches = await Deserializer.deepDeserialize(
        res.saved_searches
      )

      return { saved_searches: savedSearches, pagy: res.pagy }
    }
  },
  getSaveSearchInfinity: {
    key: savedSearchKey.getSaveSearchInfinity(),
    request: async (queryParams) => {
      const { pageParam } = queryParams

      if (!isOfType.null(pageParam)) {
        const res = await getSavedSearches({
          page: pageParam || 1
        })

        const savedSearches = await Deserializer.deepDeserialize(
          res.saved_searches
        )

        return { saved_searches: savedSearches, pagy: res.pagy }
      }

      return { saved_searches: [], pagy: {} }
    }
  },
  getSavedSearchesListingsCounter: {
    key: savedSearchKey.getSavedSearchesListingsCounter(),
    request: getSavedSearchesListingsCounter
  },
  getSavedSearchByFilters: {
    key: savedSearchKey.getSavedSearchByFilters,
    request: async ({ queryKey }) => {
      const [_key, _, params] = queryKey

      const res = await getSavedSearchByFilters(params)

      const data = await Deserializer.deepDeserialize(res)

      return data
    }
  }
}

interface UseGetSavedSearchesProps {
  params: { page: number }
  options?: UseQueryOptions<SavedSearchPaginationModel>
}

export const useGetSavedSearches = (props?: UseGetSavedSearchesProps) => {
  const { options, params } = props || {}
  const { getSavedSearches: config } = savedSearchConfig

  const state = useQuery<SavedSearchPaginationModel>(
    config.key(params),
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetSaveSearchInfinityProps {
  options?: UseInfiniteQueryOptions<SavedSearchPaginationModel>
}

export const useGetSaveSearchInfinity = (
  props?: UseGetSaveSearchInfinityProps
) => {
  const { options } = props || {}
  const { getSaveSearchInfinity: config } = savedSearchConfig

  const state = useInfiniteQuery<SavedSearchPaginationModel>(
    config.key,
    config.request,
    {
      getNextPageParam: (lastPage) => lastPage.pagy?.next,
      getPreviousPageParam: (lastPage) => lastPage.pagy?.prev,
      ...options
    }
  )

  return state
}

interface UseGetSavedSearchesListingsCounterProps {
  options?: UseQueryOptions<SavedSearchesListingsCounterModel>
}

export const useGetSavedSearchesListingsCounter = (
  props?: UseGetSavedSearchesListingsCounterProps
) => {
  const { options } = props || {}
  const { getSavedSearchesListingsCounter: config } = savedSearchConfig

  const state = useQuery<SavedSearchesListingsCounterModel>(
    config.key,
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetSavedSearchByFilters {
  params: SavedSearchDTO
  options?: UseQueryOptions<SavedSearchModel>
}

export const useGetSavedSearchByFilters = (
  props?: UseGetSavedSearchByFilters
) => {
  const { options, params } = props || {}
  const { getSavedSearchByFilters: config } = savedSearchConfig

  const state = useQuery<SavedSearchModel>(config.key(params), config.request, {
    ...options
  })

  return state
}
