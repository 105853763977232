import { Platform } from 'react-native'

import { useIntl } from 'react-intl'

import ClearActiveIcon from 'app/assets/icons/search/clear-blue.svg'
import ClearIcon from 'app/assets/icons/search/clear.svg'
import Button, { ButtonProps } from 'app/components/base/Button'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'

type ClearAllButtonProps = {
  isFilters: boolean
} & ButtonProps

const ClearAllButton: RFC<ClearAllButtonProps> = ({
  isFilters,
  sx,
  ...props
}) => {
  const intl = useIntl()

  return (
    <Button
      size="small"
      variant="text"
      sx={{
        color: isFilters ? 'text.primary' : 'text.secondary',
        ...(!IS_NATIVE_APP && { minWidth: 110 }),
        ...sx
      }}
      {...props}
      fullWidth={false}
      endIcon={
        Platform.select({
          web: isFilters ? <ClearActiveIcon /> : <ClearIcon />,
          native: ClearIcon as unknown
        }) as ButtonProps['endIcon']
      }
    >
      {intl.formatMessage({ defaultMessage: 'Clear All' })}
    </Button>
  )
}

export default ClearAllButton
