import { useEffect } from 'react'

import { SearchContextState } from '@elastic/react-search-ui/lib/esm/withSearch'

import useCurrencyState from 'app/hooks/useCurrencyState'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import { rIdleCallback } from 'app/utils/helpers/functions.helpers'

type UseRefreshPriceByCurrencyProps = Pick<
  SearchContextState,
  'filters' | 'searchTerm' | 'setSearchTerm'
>

const useRefreshPriceByCurrency = ({
  filters,
  searchTerm,
  setSearchTerm
}: UseRefreshPriceByCurrencyProps) => {
  const { currency } = useCurrencyState()

  useEffect(() => {
    const refreshPriceByCurrency = () => {
      const isPriceFilter = filters?.find((filter) => filter.field === 'price')

      if (isPriceFilter) {
        setSearchTerm(searchTerm || '', {
          shouldClearFilters: false,
          refresh: true
        })
      }
    }

    if (IS_NATIVE_APP) {
      refreshPriceByCurrency()
    } else {
      rIdleCallback(() => {
        refreshPriceByCurrency()
      })
    }
  }, [currency])
}

export default useRefreshPriceByCurrency
