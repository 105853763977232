import { defineMessages } from 'react-intl'

import { SHIPPING_STATUS_TYPE } from 'app/utils/constants/status.constants'

const messages = defineMessages({
  itemTypeGenuine: {
    defaultMessage: 'Genuine'
  },
  itemTypeAftermarket: {
    defaultMessage: 'Aftermarket'
  },
  warrantyYes: {
    defaultMessage: 'Yes'
  },
  warrantyNo: {
    defaultMessage: 'No'
  },
  conditionNew: {
    defaultMessage: 'New'
  },
  conditionUsed: {
    defaultMessage: 'Used'
  },
  shippingTypeShipOnly: {
    defaultMessage: 'Ship Only'
  },
  shippingTypeClickAndCollect: {
    defaultMessage: 'Click & Collect'
  },
  shippingTypeBoth: {
    defaultMessage: 'Both'
  },
  sortRelevance: {
    defaultMessage: 'Relevance'
  },
  sortNewest: {
    defaultMessage: 'Newest'
  },
  sortPriceDesc: {
    defaultMessage: 'Price: High to Low'
  },
  sortPriceAsc: {
    defaultMessage: 'Price: Low to High'
  },
  sortSellersRating: {
    defaultMessage: 'Seller’s Rating'
  },
  inStock: {
    defaultMessage: 'In Stock'
  },
  soldOut: {
    defaultMessage: 'Sold Out'
  },
  blocked: {
    defaultMessage: 'Blocked'
  }
})

export const itemTypeOptions = [
  {
    label: messages.itemTypeGenuine,
    value: 'genuine'
  },
  {
    label: messages.itemTypeAftermarket,
    value: 'aftermarket'
  }
]

export const warrantyOptions = [
  {
    label: messages.warrantyYes,
    value: 'yes'
  },
  {
    label: messages.warrantyNo,
    value: 'no'
  }
]

export const conditionOptions = [
  {
    label: messages.conditionNew,
    value: 'new_item'
  },
  {
    label: messages.conditionUsed,
    value: 'used_item'
  }
]

export const shippingTypeOptions = [
  {
    label: messages.shippingTypeShipOnly,
    value: SHIPPING_STATUS_TYPE.ship_only
  },
  {
    label: messages.shippingTypeClickAndCollect,
    value: SHIPPING_STATUS_TYPE.click_and_collect
  }
]

export const availabilityTypeOptions = [
  {
    label: messages.inStock,
    value: 'available'
  },
  {
    label: messages.soldOut,
    value: 'sold_out'
  },
  {
    label: messages.blocked,
    value: 'blocked'
  }
]

export const searchSortOptions = [
  {
    name: messages.sortRelevance,
    value: '',
    direction: ''
  },
  {
    name: messages.sortNewest,
    value: 'published_at',
    direction: 'desc'
  },
  {
    name: messages.sortPriceDesc,
    value: 'price',
    direction: 'desc'
  },
  {
    name: messages.sortPriceAsc,
    value: 'price',
    direction: 'asc'
  },
  {
    name: messages.sortSellersRating,
    value: 'user_rating',
    direction: 'desc'
  }
]
