import React, { forwardRef } from 'react'

import NumberFormat, {
  InputAttributes,
  NumberFormatProps
} from 'react-number-format'

import { InputAdornment } from '@mui/material'

import useCurrencyState from 'app/hooks/useCurrencyState'

import TextInput, { TextInputProps } from '../TextInput/TextInput.web'

export type TextInputNumberProps = Omit<TextInputProps, 'variant'> & {
  numberFormat?: Partial<NumberFormatProps>
  variant?: 'number' | 'currency'
  decimalScale?: NumberFormatProps['decimalScale']
  currency?: string
}

const TextInputNumber: RFC<TextInputNumberProps> = ({
  variant = 'number',
  numberFormat,
  decimalScale,
  currency,
  ...rest
}) => {
  const hasVariant = (v: TextInputNumberProps['variant']) => variant === v
  const { currency: storeCurrency, currencies } = useCurrencyState()

  const currentCurrency = currency
    ? currencies[currency]
    : currencies[storeCurrency]

  return (
    <TextInput
      {...rest}
      sx={{
        ...rest?.sx,
        ...(hasVariant('currency') && {
          '& .MuiInputBase-input': {
            paddingLeft: 0
          }
        })
      }}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        ...(hasVariant('currency') && {
          startAdornment: (
            <InputAdornment position="start">{currentCurrency}</InputAdornment>
          )
        }),
        ...rest.InputProps
      }}
      inputProps={{
        ...rest.inputProps,
        ...numberFormat,
        decimalScale: decimalScale
      }}
    />
  )
}

interface NumberFormatCustomProps extends NumberFormatProps {
  inputRef: (instance: NumberFormat<any> | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = forwardRef<
  NumberFormat<InputAttributes>,
  NumberFormatCustomProps
>((props, ref) => {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values): void => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
})

export default TextInputNumber
