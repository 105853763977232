import React from 'react'

import {
  ToggleButton as MuiToggle,
  ToggleButtonProps as MuiToggleProps
} from '@mui/material'

export type ToggleProps = Omit<MuiToggleProps, 'size'> & {
  size?: MuiToggleProps['size'] | 'extra'
  variant?: 'primary' | 'secondary'
}

const Toggle = ({
  color = 'secondary',
  size = 'medium',
  selected,
  sx,
  children,
  variant,
  ...props
}: ToggleProps) => {
  const secondary = variant === 'secondary'

  return (
    <MuiToggle
      selected={selected}
      color={color}
      size={size !== 'extra' ? size : undefined}
      sx={{
        ...(size === 'extra' && { minHeight: 64, py: 2 }),
        flex: 1,
        marginBottom: 1,
        justifyContent: secondary ? 'flex-start' : 'center',
        px: secondary ? 1.5 : 0,

        ...(!secondary && {
          maxWidth: 165,
          marginBottom: 0,

          '&:not(:last-child)': {
            marginRight: 1.5
          }
        }),
        ...sx
      }}
      {...props}
    >
      {children}
    </MuiToggle>
  )
}

export default Toggle
