import React, { useRef } from 'react'
import { Platform } from 'react-native'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useRouter } from 'solito/router'

import Box from 'app/components/base/Box'
import Button from 'app/components/base/Button'
import Dialog from 'app/components/base/Dialog'
import Text from 'app/components/base/Text'
import SelectField from 'app/components/fields/SelectField'
import { getVehicles } from 'app/services/Api/user'
import Deserializer from 'app/services/Deserializer'
import { SEARCH_LINK } from 'app/utils/constants/menu.constants'
import { MyGarageSchema } from 'app/validation/schemas/user.schemas'

interface MyGarageDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (vehicle: MyGarageVehicleModel) => void
}

const transformAllVehicles = (allVehicles: VehicleModel[]) =>
  allVehicles.map((item) => {
    const generationYears =
      item?.generation?.year_begin &&
      `[${item?.generation?.year_begin} - ${item?.generation?.year_end || ''}]`

    return {
      id: item.id,
      make: item.model.make.name,
      model: item.model.name,
      modelId: item.model.id,
      generation: item?.generation,
      serie: item?.serie,
      trim: item?.trim,
      vehicle: `${item.model.make.name} ${item.model.name} ${
        generationYears || ''
      }`
    }
  })

const MyGarageDialog: RFC<MyGarageDialogProps> = ({
  open,
  onClose,
  onConfirm
}) => {
  const { push } = useRouter()
  const { formatMessage } = useIntl()
  const load = useRef(false)
  const queryClient = useQueryClient()

  const methods = useForm<GetVehicleFV>({
    resolver: yupResolver(MyGarageSchema),
    mode: 'all'
  })

  const { isValid } = methods.formState

  const onSubmit = ({ my_garage: id }) => {
    const vehicles = queryClient.getQueryData<MyGarageVehicleModel[]>([
      'options',
      'my_garage'
    ])
    const vehicle = vehicles?.find((item) => item?.id === id)

    if (onConfirm && vehicle) {
      onConfirm(vehicle)
    } else {
      push({
        pathname: SEARCH_LINK,
        query: {
          makes: vehicle?.make,
          models: vehicle?.model,
          generation_id: vehicle?.generation?.id,
          serie_id: vehicle?.serie?.id,
          trim_id: vehicle?.trim?.id
        }
      })
    }
    load.current = true

    setTimeout(() => {
      onClose()

      load.current = false
    }, 500)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={<FormattedMessage defaultMessage="My Garage" />}
      actions={
        <Button
          size="small"
          variant="contained"
          sx={{ flex: 1 }}
          loading={load.current}
          disabled={!isValid}
          onClick={methods.handleSubmit(onSubmit)}
        >
          {formatMessage({ defaultMessage: 'Submit' })}
        </Button>
      }
    >
      <FormProvider {...methods}>
        <Box>
          <Text
            variant="body1"
            color="text.third"
            sx={{ textAlign: 'center', mb: 3 }}
          >
            {formatMessage({
              defaultMessage:
                'You can use your saved vehicle to make your search process quicker.'
            })}
          </Text>

          <SelectField
            name="my_garage"
            withFilter
            optionSettings={{
              key: 'my_garage',
              loadOptions: async () => {
                const resp = await getVehicles({})

                const allVehicles = await Deserializer.deepDeserialize(
                  resp?.all_vehicles
                )

                return transformAllVehicles(allVehicles)
              },
              optionValue: 'id',
              optionLabel: 'vehicle'
            }}
            label={formatMessage({ defaultMessage: 'Select from My Garage' })}
            sx={{
              ...Platform.select({
                web: {
                  '&>.MuiInputLabel-root': {
                    color: 'text.secondary'
                  }
                }
              })
            }}
          />
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default MyGarageDialog
