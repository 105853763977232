import { SearchContextState } from '@elastic/react-search-ui/lib/cjs/withSearch'

import { SEGMENT_CATEGORY_ATTRIBUTE_KEY } from 'app/utils/constants/elastic.constants'

import { CI_ENV, ELASTIC_DEVELOPER_NAMESPACE } from '../constants/env.constants'
import { MAKE_UNIVERSAL_OPTION } from '../constants/listing.constants'

export const transformQueryParamsToSearchParams = ({
  p,
  q,
  sort,
  order,
  ...filterParams
}: Record<string, string[] | string>) => {
  const filters = Object.entries(filterParams).map(([key, value]) => {
    const isRange = key.endsWith('-range')
    const fieldName = isRange ? key.replace('-range', '') : key
    let newValue: string | string[] | { from: number; to: number }[] = value

    if (isRange) {
      const splitValue = Array.isArray(value) ? value[0] : value

      const [from = '', to = ''] = splitValue?.split('-') || ['', '']

      const isValid = !isNaN(+from) && !isNaN(+to)

      if (isValid) {
        newValue = [{ from: +from, to: +to }]
      }
    }

    return {
      field: fieldName,
      values: Array.isArray(newValue) ? newValue : [newValue],
      type: 'any'
    }
  })

  return {
    current: Number(p) || 1,
    searchTerm: q || '',
    filters: filters || [],
    sortField: sort ? sort?.toString() : '',
    sortDirection: order ? order?.toString() : ''
  }
}

export type SearchParams = {
  searchTerm: string
  current: number
  filters: {
    field: string
    type: 'all' | 'any'
    values: string[] | { from: number; to: number }[]
  }[]
  sortDirection: string
  sortField: string
}

export const transformSearchParamsToQueryParams = ({
  searchTerm,
  current,
  filters = [],
  sortDirection,
  sortField
}: SearchParams) => {
  const newParams = filters?.reduce((acc, { field, values }) => {
    const isRange = values.some((v) => v?.from || v?.to)
    const fieldName = isRange ? `${field}-range` : field

    return {
      ...acc,
      [fieldName]: values.map((v) => (isRange ? [v.from, v.to].join('-') : v))
    }
  }, {})

  return {
    q: searchTerm,
    p: current === 1 ? null : current,
    sort: sortField,
    order: sortDirection,
    ...newParams
  }
}

type UpdateSearchParams = Partial<
  Pick<
    SearchContextState,
    | 'filters'
    | 'setFilter'
    | 'removeFilter'
    | 'setCurrent'
    | 'setSearchTerm'
    | 'setSort'
  >
>

export const updateSearchParams = ({
  setCurrent,
  setSearchTerm,
  setSort,
  setFilter,
  removeFilter,
  filters
}: UpdateSearchParams) => {
  return (newData) => {
    if (setSearchTerm) setSearchTerm(newData.searchTerm || '')

    if (setSort) setSort(newData.sortField || '', newData.sortDirection || '')

    const newFilters = newData.filters

    if (filters && removeFilter) {
      filters.forEach((oldFilter) => {
        removeFilter(oldFilter.field, undefined, oldFilter.type)
      })
    }

    if (setFilter) {
      newFilters.forEach((filter) => {
        filter.values?.forEach((value) => {
          setFilter(filter.field, value, filter.type)
        })
      })
    }

    if (newData.current && setCurrent) {
      setCurrent(Number(newData.current))
    }
  }
}

const ELASTIC_ENGINE_NAMES = ['listings'] as const

export const getElasticEngineName = (
  engineName: (typeof ELASTIC_ENGINE_NAMES)[number]
) => {
  const nameSpace =
    process.env.NEXT_DEVELOPER_NAMESPACE ||
    process.env.EXPO_DEVELOPER_NAMESPACE ||
    ELASTIC_DEVELOPER_NAMESPACE

  if (CI_ENV) {
    return `ifind-autopart-${CI_ENV}-${engineName}`
  }

  return `ifind-autopart-development-${nameSpace}-${engineName}`
}

const vehicleFilterKeys = [
  'makes',
  'models',
  'generation_id',
  'serie_id',
  'trim_id'
]

const filterToElasticKeys = {
  generation_id: 'generations_ids',
  serie_id: 'series_ids',
  trim_id: 'trims_ids'
}

export const getVehicleUniversalFilters = (
  filters: SearchContextState['filters'],
  universalParams?: VehicleUniversalParamsModel
) =>
  filters?.reduce((acc, filter) => {
    const universalId = universalParams?.[filter.field]

    if (vehicleFilterKeys.includes(filter.field)) {
      const field = filterToElasticKeys[filter.field] ?? filter.field

      return [
        ...(acc || []),
        {
          type: filter.type,
          values: [...filter?.values, MAKE_UNIVERSAL_OPTION],
          ...(!!universalId && {
            values: [...filter?.values, String(universalId)]
          }),
          field
        }
      ]
    }

    return acc
  }, [] as SearchContextState['filters'])

export const getFormattedSearchFilters = (
  allFilters: SearchContextState['filters'],
  vehicleFilters: SearchContextState['filters']
) => {
  if (!allFilters) return []

  if (!vehicleFilters) {
    return [...allFilters]
  }

  return [
    ...allFilters?.filter((item) => !vehicleFilterKeys.includes(item.field)),
    ...vehicleFilters
  ]
}

export const removeSearchAttributeFilters = ({
  filters,
  removeFilter
}: Pick<SearchContextState, 'filters' | 'removeFilter'>) => {
  filters?.forEach((filter) => {
    if (filter.field.includes(SEGMENT_CATEGORY_ATTRIBUTE_KEY)) {
      removeFilter(filter.field, undefined, filter.type)
    }
  })
}
