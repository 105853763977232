import React from 'react'

import { withSearch } from '@elastic/react-search-ui'
import type { SearchContextState } from '@elastic/react-search-ui/lib/cjs/withSearch'
import { helpers } from '@elastic/search-ui'
import { FormattedMessage } from 'react-intl'

import Button from 'app/components/base/Button'
import Flex from 'app/components/base/Flex'
import useUserAddress from 'app/hooks/useUserAddress'

const { findFilterValues } = helpers

type SearchLocationProps = {}

type FacetContainerContext = Pick<
  SearchContextState,
  'filters' | 'removeFilter' | 'setFilter'
>

const selector = ({
  filters,
  removeFilter,
  setFilter
}: SearchContextState) => ({
  filters,
  removeFilter,
  setFilter
})

const SearchLocation = ({
  filters,
  removeFilter,
  setFilter
}: FacetContainerContext & SearchLocationProps) => {
  const filed = 'full_country_name'
  const address = useUserAddress()

  const [country] = findFilterValues(filters || [], filed, 'any')

  const onRemove = () => {
    removeFilter(filed, undefined, 'any')
  }

  const onChange = (value) => {
    setFilter(filed, value, 'any')
  }

  const userCountry = address?.country
  const userFullCountryName = address?.full_country_name
  const selected = userFullCountryName && country === userFullCountryName

  return (
    <Flex>
      <Button
        sx={{
          minWidth: 'auto',
          width: 'auto',
          ...(userCountry && {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          })
        }}
        size="small"
        variant={selected ? 'outlined' : 'contained'}
        onClick={() => {
          onRemove()
        }}
      >
        <FormattedMessage defaultMessage="Global" />
      </Button>
      {userCountry && (
        <Button
          variant={selected ? 'contained' : 'outlined'}
          sx={{
            minWidth: 'auto',
            width: 'auto',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: 'none !important'
          }}
          onClick={() => {
            onChange(userFullCountryName)
          }}
          size="small"
        >
          {userCountry}
        </Button>
      )}
    </Flex>
  )
}

export default withSearch<SearchLocationProps, FacetContainerContext>(selector)(
  SearchLocation
)
