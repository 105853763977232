import React from 'react'

import { NoSsr as MuiNoSsr } from '@mui/material'

interface NoSsrProps {
  fallback?: React.ReactNode
  defer?: boolean
}

const NoSsr: RFC<NoSsrProps> = ({ fallback, defer = false, children }) => {
  return (
    <MuiNoSsr fallback={fallback} defer={defer}>
      {children}
    </MuiNoSsr>
  )
}

export default NoSsr
