import { GoogleReCaptchaProvider as ReCaptchaProvider } from 'react-google-recaptcha-v3'

import { RECAPTCHA_GOOGLE_V3_SITE_KEY } from 'app/utils/constants/env.constants'

const GoogleReCaptchaV3Provider: RFC = ({ children }) => {
  return (
    <ReCaptchaProvider reCaptchaKey={RECAPTCHA_GOOGLE_V3_SITE_KEY}>
      {children}
    </ReCaptchaProvider>
  )
}

export default GoogleReCaptchaV3Provider
