import Api from 'app/services/Api'

/**
 *  get saved searches
 */
export const getSavedSearches = (params: {
  page: number
}): Promise<SavedSearchPaginationModel> =>
  Api.get('/api/v1/users/saved_searches', {
    deserialize: true,
    params
  })

/**
 *  get saved searches listings counter
 */
export const getSavedSearchesListingsCounter =
  (): Promise<SavedSearchesListingsCounterModel> =>
    Api.get('/api/v1/users/new_documents', {
      deserialize: true
    })

/**
 * Get saved searches by filters
 */
export const getSavedSearchByFilters = (
  params: SavedSearchDTO
): Promise<SavedSearchModel> =>
  Api.get('/api/v1/users/saved_searches/find', {
    params
  })

/**
 * Create saved searches
 */
export const createSavedSearches = (data: SavedSearchDTO): Promise<void> =>
  Api.post('/api/v1/users/saved_searches', {
    data: { saved_search: data }
  })

/**
 * Delete saved searches
 */
export const deleteSavedSearch = (id: string): Promise<void> =>
  Api.del(`/api/v1/users/saved_searches/${id}`)

/**
 * Update last visit date for counter
 */
export const updateSavedSearchCounter = (id: string): Promise<void> =>
  Api.put(`/api/v1/users/saved_searches/${id}`)
