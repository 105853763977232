export const vehicleKeys = {
  vehicle: ['vehicle'] as const,
  getVehicles: (params) => [...vehicleKeys.vehicle, 'getVehicles', params],
  createVehicle: () => [...vehicleKeys.vehicle, 'createVehicle'],
  deleteVehicle: () => [...vehicleKeys.vehicle, 'deleteVehicle'],
  getVehicleUniversalParams: () => [
    ...vehicleKeys.vehicle,
    'getVehicleUniversalParams'
  ]
}
