import { useState, useCallback } from 'react'

interface UseModalReturn {
  open: boolean
  showModal: () => void
  hideModal: () => void
}

const useModal = (): UseModalReturn => {
  const [open, setOpen] = useState(false)

  const showModal = useCallback(() => setOpen(true), [])
  const hideModal = useCallback(() => setOpen(false), [])

  return { open, showModal, hideModal }
}

export default useModal
