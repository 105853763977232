import { defineMessages } from 'react-intl'

const messages = defineMessages({
  reserve_met: { defaultMessage: 'Reserve met' },
  reserve_not_met: { defaultMessage: 'Reserve not met' },
  no_reserve: { defaultMessage: 'No Reserve' },
  reserve_lowered: { defaultMessage: 'Reserve lowered' }
})

export const AUCTION_RESERVE_STATUS = {
  reserve_met: 'reserve_met',
  reserve_not_met: 'reserve_not_met',
  no_reserve: 'no_reserve',
  reserve_lowered: 'reserve_lowered'
} as const

export const auctionReserveText = {
  [AUCTION_RESERVE_STATUS.reserve_met]: messages.reserve_met,
  [AUCTION_RESERVE_STATUS.reserve_not_met]: messages.reserve_not_met,
  [AUCTION_RESERVE_STATUS.no_reserve]: messages.no_reserve,
  [AUCTION_RESERVE_STATUS.reserve_lowered]: messages.reserve_lowered
}
