import React from 'react'
import { Platform } from 'react-native'

import FormControl, { FormControlProps } from 'app/components/base/FormControl'
import Text from 'app/components/base/Text'
import Toggle, { ToggleProps } from 'app/components/base/Toggle'
import ToggleGroup from 'app/components/base/ToggleGroup'
import type { OptionModel } from 'app/hooks/useOptions'

import withFacet, { FacetComponentProps } from '../components/withFacet'

export type ToggleButtonFilterProps = Omit<
  ToggleProps,
  'variant' | 'value' | 'unselectable'
> & {
  label: FormControlProps['label']
  options: OptionModel[]
}

const ToggleButtonFilter = ({
  label,
  filter: { onSelect, values },
  options,
  ...props
}: ToggleButtonFilterProps & FacetComponentProps) => {
  const selectedValue = values[0]

  return (
    <FormControl
      fullWidth={false}
      label={label}
      labelProps={{ sx: { color: 'text.primary' } }}
    >
      <ToggleGroup
        value={selectedValue || ''}
        unselectable
        onChange={(value) => {
          onSelect(value)
        }}
      >
        {options.map(({ label, value }) => (
          <Toggle
            variant="primary"
            key={value}
            value={value}
            size="small"
            sx={{
              fontWeight: 300,
              fontSize: '16px'
            }}
            {...Platform.select({
              native: {
                onPress: () => onSelect(value),
                selected: value === selectedValue
              }
            })}
            {...props}
          >
            <Text {...Platform.select({ web: { component: 'span' } })}>
              {label}
            </Text>
          </Toggle>
        ))}
      </ToggleGroup>
    </FormControl>
  )
}

export default withFacet<ToggleButtonFilterProps>(ToggleButtonFilter)
