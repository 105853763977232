import { MAKE_UNIVERSAL_OPTION } from 'app/utils/constants/listing.constants'
import { omitEmptyValues } from 'app/utils/helpers/form.helpers'
import { checkUniversalMakeOption } from 'app/utils/helpers/functions.helpers'

export const generalInitializeForm = (data: ListingModel) => {
  const {
    attachments,
    currency,
    slug,
    status,
    sub_category,
    models,
    title,
    id,
    warranty_valid_until,
    description,
    generations,
    series,
    trims,
    category_related_options_attributes,
    ...otherData
  } = data

  const sub_category_id = data?.sub_category?.id
    ? String(data?.sub_category?.id)
    : ''

  const categoryAttributes = category_related_options_attributes?.map(
    ({ attribution_id, option_id, ...rest }) => ({
      ...rest,
      attribution_id: String(attribution_id),
      option_id: String(option_id)
    })
  )

  const makes = data?.makes?.map((make) => make.name)
  const isUniversalMakeOption = makes?.[0] === MAKE_UNIVERSAL_OPTION

  const transformValue = {
    ...otherData,
    makes: data?.makes?.map((make) => make.name),
    ...(!isUniversalMakeOption && {
      model: data?.models?.map((model) => model.id),
      generation: generations?.map((generation) => generation.id),
      series: series?.map((serie) => serie.id),
      trim: trims?.map((trim) => trim.id)
    }),
    category: data?.sub_category?.parent.name,
    sub_category_id,
    warranty_valid_until,
    warranty: warranty_valid_until ? 'yes' : 'no',
    category_related_options_attributes: categoryAttributes
  }

  return transformValue
}

export const createGeneralValuesDto = (values: ListingFV) => {
  const {
    category,
    makes,
    warranty,
    model,
    part_number,
    sub_category_id,
    warranty_valid_until,
    condition,
    item_type,
    description,
    generation,
    series,
    trim,
    allMakes,
    progress_step,
    category_related_options_attributes
  } = values

  const transformedModel = model?.map((item) => ({ model_id: Number(item) }))
  const transformedWarranty =
    warranty_valid_until && warranty === 'yes' ? warranty_valid_until : ''
  const transformedMake = makes?.map((item) => ({ make_name: item }))
  const categoryAttributes = category_related_options_attributes?.reduce(
    (acc, value) => {
      if (Object.values(omitEmptyValues(value)).length === 0) return acc

      if (value.attribution_id && value.option_id) return [...acc, value]

      const transformedValue = { _destroy: true, id: value.id }
      return [...acc, transformedValue]
    },
    [] as CategoryAttribute[]
  )

  const { isUniversalMakeOption } = checkUniversalMakeOption({
    selectedMakes: makes,
    allMakes
  })

  const transformedGenerations = !!generation
    ? generation?.map((item) => ({
        generation_id: Number(item)
      }))
    : []
  const transformedSeries = !!series
    ? series?.map((item) => ({
        serie_id: Number(item)
      }))
    : []
  const transformedTrims = !!trim
    ? trim?.map((item) => ({ trim_id: Number(item) }))
    : []

  const valuesDto: Partial<ListingDTO> = {
    listing_models_attributes: transformedModel,
    ...(isUniversalMakeOption && {
      listing_makes_attributes: transformedMake
    }),
    part_number,
    sub_category_id,
    warranty_valid_until: transformedWarranty,
    condition,
    item_type,
    description,
    listing_generations_attributes: transformedGenerations,
    listing_series_attributes: transformedSeries,
    listing_trims_attributes: transformedTrims,
    progress_step,
    category_related_options_attributes: categoryAttributes
  }

  return valuesDto
}

export const generateYearsOptions = (from: number) => {
  const yearOptions = Array.from(
    { length: new Date().getFullYear() - from },
    (_, i) => {
      const year = new Date().getFullYear() - i

      return {
        label: `${year}`,
        value: `${year}`
      }
    }
  )

  return yearOptions
}

export const disablePrevDates = (startDate) => {
  const startSeconds = Date.parse(startDate)
  return (date) => {
    return Date.parse(date) < startSeconds
  }
}
