import dynamic from 'next/dynamic'
import React from 'react'

import LazyHydrate from 'react-lazy-hydration'

import NeedHelp from 'app/features/HomePageFeature/NeedHelp'
import ShowMore from 'app/features/HomePageFeature/ShowMore'
import Videos from 'app/features/HomePageFeature/Videos'

import HomeViewContainer from './components/HomeViewContainer'
import SearchSection from './components/SearchSection'

const VideosContainer = dynamic(() => import('./components/VideosContainer'), {
  ssr: true
})

const HomeView = () => {
  return (
    <>
      <SearchSection />

      <LazyHydrate whenVisible>
        <VideosContainer>
          <HomeViewContainer>
            <Videos />
          </HomeViewContainer>
        </VideosContainer>
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <NeedHelp />
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <HomeViewContainer>
          <ShowMore />
        </HomeViewContainer>
      </LazyHydrate>
    </>
  )
}

export default React.memo(HomeView)
