import React from 'react'

import Box from 'app/components/base/Box'

import GoogleReCaptchaV3Provider from '~/providers/GoogleReCaptchaV3Provider'

import FilterPanelDesktop from './FilterPanelDesktop'
import FilterPanelMobile from './FilterPanelMobile'

const FilterPanel = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        pt: { xs: 2, md: 4 }
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <GoogleReCaptchaV3Provider>
          <FilterPanelDesktop />
          <FilterPanelMobile />
        </GoogleReCaptchaV3Provider>
      </Box>
    </Box>
  )
}

export default React.memo(FilterPanel)
