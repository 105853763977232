import dynamic from 'next/dynamic'

const ChipLabel = dynamic(() => import('app/components/common/ChipLabel'), {
  ssr: false
})

const ReservedLabel = dynamic(
  () => import('app/components/common/ReservedLabel'),
  {
    ssr: false
  }
)
const SoldOutLabel = dynamic(
  () => import('app/components/common/SoldOutLabel'),
  {
    ssr: false
  }
)

export { ChipLabel, ReservedLabel, SoldOutLabel }
