import React from 'react'
import { Platform } from 'react-native'

import NoSsr from 'app/components/base/NoSsr'
import Select, { SelectProps } from 'app/components/base/Select'
import Skeleton from 'app/components/base/Skeleton'

import withFacet, { FacetComponentProps } from '../components/withFacet'

export type SelectFilterProps = Omit<
  SelectProps,
  'onChange' | 'value' | 'variant'
> & { fixedPaperWidth?: boolean; transformValue?: (value: any) => any }

const SelectFilter: RFC<SelectFilterProps & FacetComponentProps> = ({
  multiple,
  label,
  filter: { values, options, onSelect, field },
  disabled,
  optionSettings,
  helperText,
  divider,
  fixedPaperWidth,
  transformValue = (v) => v,
  ...props
}) => {
  const value = multiple ? values : values[0]

  const handleCahnge = (v: any) => {
    const value = v?.target?.value || v

    onSelect(transformValue(value))
  }

  const hasCustomOptions =
    !!optionSettings?.loadOptions || !!optionSettings?.options

  return (
    <NoSsr
      fallback={
        <Skeleton
          sx={{
            height: 78,
            ...props.sx
          }}
        />
      }
    >
      <Select
        label={label}
        helperText={helperText}
        divider={divider}
        value={value}
        multiple={multiple}
        variant={
          Platform.select({
            web: 'select',
            native: 'picker'
          }) as SelectProps['variant']
        }
        optionSettings={{
          key: ['filterOptions', field],
          unselectable: true,
          ...(!hasCustomOptions && {
            options: options,
            optionLabel: 'value',
            optionValue: 'value'
          }),
          ...optionSettings
        }}
        {...(fixedPaperWidth && { PaperSx: { width: 280 } })}
        {...props}
        disabled={disabled}
        onChange={handleCahnge}
      />
    </NoSsr>
  )
}

export default withFacet<SelectFilterProps>(SelectFilter)
