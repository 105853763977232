import { ReactElement, ReactNode } from 'react'
import { Platform } from 'react-native'

import BlurSVG from 'app/assets/icons/blur.svg'

import Box from '../base/Box'
import Button from '../base/Button'
import Stack, { StackProps } from '../base/Stack'
import Text from '../base/Text'

interface EmptyStateProps {
  image: ReactElement
  text: string | ReactNode
  subtitle?: string | ReactNode
  buttonText?: string
  onClick?: () => void
  hideButton?: boolean
  contentSx?: StackProps['sx']
  disabledButton?: boolean
}

const EmptyState: RFC<EmptyStateProps> = ({
  image,
  text,
  buttonText,
  onClick,
  hideButton,
  subtitle,
  contentSx,
  disabledButton,
  ...props
}) => {
  return (
    <Stack
      spacing={2}
      direction="column"
      sx={{
        ...Platform.select({
          web: {
            pt: 6,
            pb: 4
          }
        }),
        alignItems: 'center'
      }}
      {...props}
    >
      <Stack
        direction="column"
        spacing={3}
        {...Platform.select({
          web: {
            sx: {
              maxWidth: 412,
              ...contentSx
            }
          },
          native: {
            sx: {
              justifyContent: 'center',
              alignItems: 'center'
            }
          }
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            mb: 1
          }}
        >
          <Box
            sx={{
              position: 'absolute'
            }}
          >
            <BlurSVG />
          </Box>
          {image}
        </Box>

        <Stack direction="column" spacing={2}>
          <Text
            {...Platform.select({
              web: {
                variant: 'h2'
              },
              native: {
                variant: 'h3'
              }
            })}
            sx={{ textAlign: 'center', color: 'text.primary' }}
          >
            {text}
          </Text>

          {subtitle ? (
            <Text
              variant="body1"
              sx={{ textAlign: 'center', color: 'text.third' }}
            >
              {subtitle}
            </Text>
          ) : (
            <></>
          )}
        </Stack>

        <Box sx={{ width: '100%', minHeight: 43, textAlign: 'center' }}>
          {!hideButton && (
            <Button
              variant="contained"
              disabled={disabledButton}
              onClick={onClick}
              sx={{
                width: 'auto',
                minWidth: 160
              }}
            >
              {buttonText}
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  )
}

export default EmptyState
