import React, { useEffect, useState } from 'react'

import {
  Slider as MuiSlider,
  SliderProps as MuiSliderProps
} from '@mui/material'

export type SliderProps = MuiSliderProps & {
  value: [number, number]
  onChange: (value: number | number[]) => void
}

const Slider = ({ value, onChange, sx, ...props }: SliderProps) => {
  const [inputValue, setInputValue] = useState<number[] | number>([0, 0])

  useEffect(() => {
    if (value) setInputValue(value)
  }, [value])

  return (
    <MuiSlider
      value={inputValue}
      valueLabelDisplay="auto"
      color="secondary"
      onChange={(_, v) => setInputValue(v)}
      onChangeCommitted={(_, v) => onChange(v)}
      sx={{
        '& .MuiSlider-thumb': {
          height: 20,
          width: 20,
          zIndex: 0,
          background: 'linear-gradient(90deg, #17D3FF 0%, #00EA90 100%)',
          '&::before': {
            height: 18,
            width: 18,
            backgroundColor: '#fff',
            boxShadow: 'none'
          }
        },
        '& .MuiSlider-rail': {
          background: 'linear-gradient(90deg, #17D3FF 0%, #00EA90 100%)',
          height: '4px'
        },
        '& .MuiSlider-track': {
          border: 'none',
          background: 'linear-gradient(90deg, #17D3FF 0%, #00EA90 100%)',
          height: '4px'
        },
        ...sx
      }}
      {...props}
    />
  )
}

export default Slider
