import Box from 'app/components/base/Box'

const GradientFilter = () => {
  return (
    <Box
      className="gradientFilter"
      sx={{
        position: 'absolute',
        width: '100%',
        minHeight: 225,
        '&:after': {
          content: '" "',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          background: 'transparent',
          backgroundImage:
            'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)'
        }
      }}
    />
  )
}

export default GradientFilter
