export const savedSearchKey = {
  userSavedSearch: ['savedSearches'] as const,
  getSavedSearches: (params) => [
    ...savedSearchKey.userSavedSearch,
    'getSavedSearches',
    params
  ],
  getSavedSearchByFilters: (params) => [
    ...savedSearchKey.userSavedSearch,
    'getSavedSearchByFilters',
    params
  ],
  getSavedSearchesListingsCounter: () => [
    ...savedSearchKey.userSavedSearch,
    'getSavedSearchesListingsCounter'
  ],
  createSavedSearches: () => [
    ...savedSearchKey.userSavedSearch,
    'createSavedSearches'
  ],
  deleteSavedSearch: () => [
    ...savedSearchKey.userSavedSearch,
    'deleteSavedSearch'
  ],
  getSaveSearchInfinity: () => [
    ...savedSearchKey.userSavedSearch,
    'getSaveSearchInfinity'
  ],
  updateSavedSearchCounter: () => [
    ...savedSearchKey.userSavedSearch,
    'updateSavedSearchCounter'
  ]
}
