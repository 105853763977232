import qs from 'query-string'

export const parseQueryParams = (search: string) => {
  return qs.parse(search, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|'
  })
}

export const stringifyQueryParams = (object: Record<string, any>) => {
  return qs
    .stringify(object, {
      arrayFormat: 'separator',
      arrayFormatSeparator: '|',
      skipEmptyString: true,
      skipNull: true
    })
    .replace('?', '')
}
