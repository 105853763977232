import React, { forwardRef, memo } from 'react'

import { TextField, TextFieldProps, InputAdornment } from '@mui/material'

export type TextInputProps = Omit<TextFieldProps, 'variant'> & {
  endAdornment?: React.ReactNode
  startAdornment?: React.ReactNode
}

const TextInput = (
  {
    endAdornment,
    startAdornment,
    InputProps,
    multiline,
    ...props
  }: TextInputProps,
  ref: any
) => {
  return (
    <TextField
      variant="outlined"
      ref={ref}
      size="small"
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
        ...InputProps
      }}
      multiline={multiline}
      {...(multiline && {
        rows: 5
      })}
      {...props}
    />
  )
}

export default memo(forwardRef(TextInput))
