import React from 'react'

import Container, { ContainerProps } from 'app/components/base/Container'

type Props = {
  children: React.ReactNode
  sx?: ContainerProps['sx']
}

const HomeViewContainer = ({ sx, children }: Props) => {
  return (
    <Container maxWidth="lg" sx={{ px: { xs: 2, md: '45px' }, ...sx }}>
      {children}
    </Container>
  )
}

export default HomeViewContainer
