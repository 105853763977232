import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import GooglePolicyTerms from 'app/components/common/GooglePolicyTerms'
import useExecuteGoogleRecaptcha from 'app/hooks/useExecuteGoogleRecaptcha'
import useFormErrorHandler from 'app/hooks/useFormErrorHandler'
import Alert from 'app/services/Alert'
import { isErrorHandlerType } from 'app/services/ErrorHandler'
import { useSendMissingVehicleMutation } from 'app/services/Query/user/user.mutation'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import { generateAgentToken } from 'app/utils/helpers/functions.helpers'
import { VehicleMissingSchema } from 'app/validation/schemas/user.schemas'

import Button from '../base/Button'
import Dialog from '../base/Dialog'
import Stack from '../base/Stack'
import Text from '../base/Text'
import TextField from '../fields/TextField'

interface MissingVehicleDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const MissingVehicleDialog = ({
  open,
  onClose,
  onConfirm
}: MissingVehicleDialogProps): JSX.Element => {
  const { formatMessage } = useIntl()

  const methods = useForm<MissingVehicleFV>({
    resolver: yupResolver(VehicleMissingSchema),
    mode: 'all'
  })

  const executeGoogleRecaptcha = useExecuteGoogleRecaptcha()
  const handleFormError = useFormErrorHandler(methods.setError)
  const { mutateAsync } = useSendMissingVehicleMutation()

  const { isSubmitting, isDirty } = methods.formState

  const onSubmit = async (values: MissingVehicleFV) => {
    try {
      if (!executeGoogleRecaptcha) throw new Error()

      const captchaToken = await executeGoogleRecaptcha('onSubmit')
      const agentToken = generateAgentToken()

      await mutateAsync({
        vehicle: values,
        agent_token: agentToken,
        captcha_token: captchaToken
      })

      Alert.success(formatMessage({ defaultMessage: 'Successfully sent' }))

      onConfirm()
    } catch (error) {
      if (isErrorHandlerType(error)) handleFormError(error?.data)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={formatMessage({ defaultMessage: 'Vehicle Missing?' })}
      titleSx={{ mb: 3 }}
      actions={
        <Stack flex={1} spacing={2} alignItems="center">
          <Stack width="100%" alignItems="flex-end">
            <Stack direction="row" spacing={2}>
              <Button
                size="small"
                variant="outlined"
                sx={{ flex: 1, width: 124 }}
                onClick={onClose}
              >
                {formatMessage({ defaultMessage: 'Cancel' })}
              </Button>

              <Button
                size="small"
                variant="contained"
                sx={{ flex: 1, width: 124 }}
                loading={isSubmitting}
                disabled={!isDirty}
                onClick={methods.handleSubmit(onSubmit)}
              >
                {formatMessage({ defaultMessage: 'Send' })}
              </Button>
            </Stack>
          </Stack>

          <GooglePolicyTerms />
        </Stack>
      }
      {...(!IS_NATIVE_APP && {
        dialogSx: {
          '& .MuiDialog-paper': {
            py: 5,
            px: { xs: 2, sm: 6 },
            m: { xs: 2, md: 4 },
            width: '100%',
            maxWidth: '540px'
          }
        }
      })}
    >
      <Text
        variant="body2"
        sx={{
          color: 'text.third',
          mb: 3,
          textAlign: 'center'
        }}
      >
        {formatMessage({
          defaultMessage: "Let us know which make and model you can't find"
        })}
      </Text>

      <Stack spacing={2}>
        <FormProvider {...methods}>
          <Stack direction="column" spacing={2} alignItems="center">
            <TextField
              name="make"
              label={formatMessage({ defaultMessage: 'Make' })}
            />

            <TextField
              name="model"
              label={formatMessage({ defaultMessage: 'Model' })}
            />
          </Stack>
        </FormProvider>
      </Stack>
    </Dialog>
  )
}

export default MissingVehicleDialog
