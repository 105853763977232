import React from 'react'

import {
  Container as MuiContainer,
  ContainerProps as MuiContainerProps
} from '@mui/material'

export type ContainerProps = MuiContainerProps & {}

const Container = ({ children, ...props }: ContainerProps) => {
  return <MuiContainer {...props}>{children}</MuiContainer>
}

export default Container
