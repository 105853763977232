import React from 'react'

import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper'

export type PaperProps = MuiPaperProps & {}

const Paper = ({ children, sx, ...props }: PaperProps) => {
  return (
    <MuiPaper
      sx={{
        boxShadow:
          '0px 12px 24px -4px rgba(145, 158, 171, 0.15), 0px 0px 2px rgba(199, 207, 216, 0.7)',
        p: { xs: 2.5, md: 5 },
        borderRadius: '16px',
        ...sx
      }}
      {...props}
    >
      {children}
    </MuiPaper>
  )
}

export default Paper
