import { Platform } from 'react-native'

import { withSearch } from '@elastic/react-search-ui'
import { SearchContextState } from '@elastic/react-search-ui/lib/esm/withSearch'
import { useIntl } from 'react-intl'

import BookmarkActiveIcon from 'app/assets/icons/homePage/bookmark-active.svg'
import BookmarkInActiveIcon from 'app/assets/icons/homePage/bookmark-inactive.svg'
import Button, { ButtonProps } from 'app/components/base/Button'
import Text, { TextProps } from 'app/components/base/Text'
import useSavedSearch from 'app/features/Profile/SavedSearchFeature/hooks/useSavedSearch'
import { transformSearchParamsToQueryParams } from 'app/utils/helpers/elastic.helpers'

interface SaveSearchButtonProps extends ButtonProps {
  labelProps?: TextProps
  sx?: ButtonProps['sx']
}

const selector = ({ filters, searchTerm, ...rest }: SearchContextState) => ({
  filters,
  searchTerm,
  ...rest
})

const SaveSearchButton = ({
  filters,
  searchTerm,
  labelProps,
  ...restProps
}: SaveSearchButtonProps & SearchContextState) => {
  const intl = useIntl()

  const searchParams = transformSearchParamsToQueryParams({
    filters,
    searchTerm
  } as any)

  const { isSavedSearch, isLoading, onSavedSearch } = useSavedSearch({
    searchParams
  })

  const Icon = isSavedSearch ? BookmarkActiveIcon : BookmarkInActiveIcon

  return (
    <Button
      variant="outlined"
      disabled={isLoading}
      onClick={onSavedSearch}
      {...Platform.select({
        web: { startIcon: <Icon /> },
        native: {
          icon: Icon
        }
      })}
      {...restProps}
    >
      <Text {...labelProps}>
        {intl.formatMessage({ defaultMessage: 'Save Search' })}
      </Text>
    </Button>
  )
}

export default withSearch<SaveSearchButtonProps, SearchContextState>(selector)(
  SaveSearchButton
)
