import { subscribeWithSelector } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export interface SignInPopupStore {
  isOpenPopup?: boolean
  showPopup?: () => void
  hidePopup?: () => void
  updateStore: (store: Partial<SignInPopupStore>) => void
}

const useSignInPopupStore = createWithEqualityFn<SignInPopupStore>()(
  subscribeWithSelector((set) => ({
    isOpenPopup: false,
    showPopup: undefined,
    hidePopup: undefined,
    updateStore: (store) => set(() => store)
  })),
  shallow
)

export default useSignInPopupStore
