import dynamic from 'next/dynamic'
import React, { SVGProps } from 'react'

import { withSearch } from '@elastic/react-search-ui'
import type { SearchContextState } from '@elastic/react-search-ui/lib/cjs/withSearch'
import { useIntl } from 'react-intl'

import Box from 'app/components/base/Box'
import EmptyState from 'app/components/common/EmptyState'
import useRefreshPriceByCurrency from 'app/features/Profile/SavedSearchFeature/hooks/useRefreshPriceByCurrency'

import { SearchList } from './components/SearchList'

const EmptyStateImage = dynamic(
  () => import('app/assets/icons/search-empty.svg')
) as React.ComponentType<SVGProps<SVGSVGElement>>

const selector = ({
  results,
  isLoading,
  wasSearched,
  totalResults,
  resultsPerPage,
  filters,
  searchTerm,
  setSearchTerm,
  error
}: SearchContextState) => ({
  results,
  isLoading,
  wasSearched,
  totalResults,
  resultsPerPage,
  filters,
  searchTerm,
  setSearchTerm,
  error
})

const SearchAppResult = ({
  results = [],
  isLoading,
  wasSearched,
  totalResults,
  resultsPerPage,
  filters,
  searchTerm,
  setSearchTerm,
  error
}) => {
  const isEmpty = (wasSearched && totalResults === 0) || error

  const { formatMessage } = useIntl()

  useRefreshPriceByCurrency({ filters, searchTerm, setSearchTerm })

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: 500,
        mt: { xs: 1, md: 2 },
        ...(isEmpty && {
          height: 500,
          justifyContent: 'center',
          alignItems: 'center'
        })
      }}
    >
      {isEmpty ? (
        <EmptyState
          image={<EmptyStateImage width={64} height={64} />}
          text={formatMessage({
            defaultMessage: 'There are no items matching the selected filters'
          })}
          subtitle={formatMessage({
            defaultMessage:
              'Get notified when items matching to your vehicle and we will update you!'
          })}
          hideButton
        />
      ) : (
        <SearchList
          results={results}
          resultsPerPage={resultsPerPage}
          isLoading={isLoading}
        />
      )}
    </Box>
  )
}

export default React.memo(withSearch(selector)(SearchAppResult))
