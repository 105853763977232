import { useIntl } from 'react-intl'

import { stringifyQueryParams } from 'app/services/QueryParams/utils'
import { SHIPPING_STATUS_TYPE } from 'app/utils/constants/status.constants'
import { omitEmptyValues } from 'app/utils/helpers/form.helpers'
import { isOfType } from 'app/utils/helpers/type.helpers'

export const shippingTypes = {
  [SHIPPING_STATUS_TYPE.ship_only]: 'Ship Only',
  [SHIPPING_STATUS_TYPE.click_and_collect]: 'Click & Collect',
  [SHIPPING_STATUS_TYPE.both]: 'Both'
}

export const conditionTypes = {
  new_item: 'new',
  used_item: 'used'
}

export const useGenerateSaveSearchesInfo = (values: SavedSearchModel) => {
  const { formatMessage, formatNumber } = useIntl()
  const {
    condition,
    part_number,
    warranty,
    shipping_types,
    item_type,
    price_from,
    price_to,
    city,
    country,
    currency,
    serie_name,
    trim_name,
    international_shipping
  } = (omitEmptyValues(values) as SavedSearchModel) || ({} as SavedSearchModel)

  const formattedPriceFrom = formatNumber(Number(price_from), {
    currency,
    style: 'currency',
    minimumFractionDigits: 0
  })
  const formattedPriceTo = formatNumber(Number(price_to), {
    currency,
    style: 'currency',
    minimumFractionDigits: 0
  })

  const location = country ? (city ? `${country}, ${city} ` : `${country}`) : ''
  const priceRange =
    price_from || price_to ? `${formattedPriceFrom}-${formattedPriceTo} ` : ''

  const shippingType = shippingTypes[shipping_types?.[0] ?? '']

  return [
    {
      id: '0',
      title: formatMessage({ defaultMessage: 'Part Number:' }),
      type: 'partNumber',
      value: part_number
    },
    {
      id: '1',
      title: formatMessage({ defaultMessage: 'Condition:' }),
      type: 'condition',
      value: conditionTypes[condition]
    },
    {
      id: '2',
      title: formatMessage({ defaultMessage: 'Warranty:' }),
      type: 'warranty',
      value: warranty
    },
    {
      id: '3',
      title: formatMessage({ defaultMessage: 'Type:' }),
      type: 'itemType',
      value: item_type
    },
    {
      id: '4',
      title: formatMessage({ defaultMessage: 'Shipping type:' }),
      type: 'shipping',
      value: shippingType
    },
    {
      id: '5',
      title: formatMessage({ defaultMessage: 'Pricing range:' }),
      type: 'pricing',
      value: priceRange
    },
    {
      id: '6',
      title: formatMessage({ defaultMessage: 'Location:' }),
      type: 'location',
      value: location
    },
    {
      id: '7',
      title: formatMessage({ defaultMessage: 'Series:' }),
      type: 'serie',
      value: serie_name
    },
    {
      id: '8',
      title: formatMessage({ defaultMessage: 'Trim:' }),
      type: 'trim',
      value: trim_name
    },
    {
      id: '9',
      title: formatMessage({ defaultMessage: 'Delivery Option:' }),
      type: 'internationalShipping',
      value:
        international_shipping === 'yes' ? 'International Shipping' : undefined
    }
  ]
}

export const generateSavedSearchLink = (values: SavedSearchModel) => {
  const {
    currency,
    id,
    price_to,
    price_from,
    query,
    category_name,
    sub_category_name,
    country,
    last_visit_date,
    new_listings_counter,
    status,
    generation_name,
    serie_name,
    trim_name,
    international_shipping,
    category_options,
    shipping_types,
    ...otherValues
  } = values || {}
  const hasPrices = (Number(price_from) && Number(price_to)) >= 0
  const isIntShipping = international_shipping === 'yes'

  const namePriceField = 'price-range'

  const attribution = category_options?.reduce((acc, { name, attribution }) => {
    return { ...acc, [attribution.key]: name }
  }, {})

  const shippingType = shipping_types?.[0]

  return stringifyQueryParams({
    ...(hasPrices && {
      [namePriceField]: `${price_from}-${price_to}`
    }),
    ...(query && { q: query }),
    ...(country && { full_country_name: country }),
    ...(isIntShipping && { international_shipping }),
    ...(!!shippingType && { shipping_types: shippingType }),
    ...attribution,
    ...otherValues,
    order: 'desc',
    sort: 'published_at'
  })
}

export const generateSaveSearchesDto = (values) => {
  const internationalShipping = values?.international_shipping
    ? values?.international_shipping
    : 'no'

  const result = omitEmptyValues({
    ...values,
    international_shipping: internationalShipping
  })

  const ignore = [
    'order',
    'sort',
    'country',
    'full_country_name',
    'international_shipping',
    'shipping_types'
  ]

  const transformResult = Object.entries(result || {}).reduce(
    (acc, [key, value]) => {
      if (key === 'price-range') {
        const priceRange = String(value)

        const price_from = priceRange.split('-')[0]
        const price_to = priceRange.split('-')[1]

        acc.price_from = price_from ? price_from : '0'
        acc.price_to = price_to ? price_to : '999999'
      }

      if (key === 'full_country_name') {
        acc.country = String(value)
      }

      if (key === 'international_shipping') {
        const isArrayValue = isOfType.array(value)

        if (isArrayValue) {
          acc.international_shipping = value?.flat()?.[0]
        } else {
          acc.international_shipping = value as string
        }
      }

      if (key === 'shipping_types') {
        if (isOfType.string(value)) {
          acc.shipping_types = [value]
        }

        if (isOfType.array(value)) {
          acc.shipping_types = value
        }
      }

      if (value && !ignore.includes(key)) acc[key] = String(value)

      return acc
    },
    {} as SavedSearchDTO
  )

  // @ts-ignore
  const { q: query, ...otherValues } = transformResult || {}

  return {
    ...(query && { query }),
    ...otherValues
  }
}

export const generateMobileCategory = ({ values, warranty }) => {
  const omitValues = ['partNumber', 'pricing']

  return values
    .map((item) => {
      if (item.type === 'warranty') {
        return !!item.value && `${warranty} ${item.value} `
      }

      if (omitValues.includes(item.type)) {
        return
      }

      return !!item.value && `${item.value} `
    })
    .filter(Boolean)
    .join('/ ')
}

export const generateTemplate = ({
  generatedValues,
  needSlash,
  templateType
}: {
  generatedValues: {
    id: string
    title: string
    type: string
    value?: string
  }[]
  needSlash: boolean
  templateType: 'partNumber' | 'pricing'
}) => {
  const isPartNumber = templateType === 'partNumber'

  const { value } =
    generatedValues.find((item) => item.type === templateType) || {}

  const slash = needSlash ? ' / ' : ''

  const template = value
    ? isPartNumber
      ? `${value}${slash}`
      : `${slash}${value}`
    : ''

  return template
}

export const generateTitle = (values) => {
  const titleInfo = values
    .map((item) => !!item && item)
    .filter(Boolean)
    .join(' ')

  return titleInfo
}

export const generateCategoriesInfo = (values) =>
  values.filter(Boolean).join(', ')
