import React from 'react'

import Box, { BoxProps } from '../Box'

type AspectRatioProps = BoxProps & {
  ratio: number
}

const AspectRatio: RFC<AspectRatioProps> = ({
  ratio = 4 / 3,
  children,
  sx,
  ...rest
}) => {
  return (
    <Box
      width="100%"
      sx={{
        // @ts-ignore
        '&': {
          paddingTop: `calc((1 / ${ratio}) * 100%)`,

          '@supports (aspect-ratio: 1)': {
            aspectRatio: `${ratio}`,
            paddingTop: 'initial'
          }
        },
        ...sx
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default AspectRatio
