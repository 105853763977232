import {
  MutateOptions,
  QueryKey,
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient
} from 'react-query'

import {
  createSavedSearches,
  deleteSavedSearch,
  updateSavedSearchCounter
} from 'app/services/Api/savedSearch'

import { savedSearchKey } from './saved-search.key'

interface MutationConfig {
  createSavedSearches: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, SavedSearchDTO, unknown>
  }
  deleteSavedSearch: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, string, unknown>
  }
  updateSavedSearchCounter: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, string, unknown>
  }
}

const savedSearchMutationConfig: MutationConfig = {
  createSavedSearches: {
    key: savedSearchKey.createSavedSearches(),
    request: createSavedSearches
  },
  deleteSavedSearch: {
    key: savedSearchKey.deleteSavedSearch(),
    request: deleteSavedSearch
  },
  updateSavedSearchCounter: {
    key: savedSearchKey.updateSavedSearchCounter(),
    request: updateSavedSearchCounter
  }
}

export const useCreateSavedSearches = (
  options?: MutateOptions<void, string, SavedSearchDTO>
) => {
  const { createSavedSearches: config } = savedSearchMutationConfig

  const state = useMutation(config.key, config.request, options)

  return state
}

export const useDeleteSavedSearch = (
  options?: MutateOptions<unknown, string, unknown>
) => {
  const queryClient = useQueryClient()

  const { deleteSavedSearch: config } = savedSearchMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(savedSearchKey.userSavedSearch)
    },
    ...options
  })

  return state
}

export const useUpdateSavedSearchCounterMutation = () => {
  const { updateSavedSearchCounter: config } = savedSearchMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}
