import React, { useEffect } from 'react'

import { withSearch } from '@elastic/react-search-ui'
import type { SearchContextState } from '@elastic/react-search-ui/lib/cjs/withSearch'

import useAppStore, { AppStore } from 'app/store/app.store'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import { rIdleCallback } from 'app/utils/helpers/functions.helpers'

export type ContainerContext = Pick<
  SearchContextState,
  'setSearchTerm' | 'setSort' | 'clearFilters'
>

export type ContainerProps = {}

export type ComponentProps = {}

type InitialFilterControlProps = ContainerProps & ContainerContext & {}

const selector = (state: AppStore) => ({
  setElasticClearState: state.setElasticClearState
})

const InitialFilterControl = ({
  setSearchTerm,
  setSort,
  clearFilters
}: InitialFilterControlProps & ComponentProps) => {
  const { setElasticClearState } = useAppStore(selector)

  useEffect(() => {
    const clearState = () => {
      setElasticClearState(() => {
        clearFilters()
        setSearchTerm('')
        setSort('', '')
      })
    }

    if (IS_NATIVE_APP) {
      clearState()
    } else {
      rIdleCallback(() => {
        clearState()
      })
    }
  }, [])

  return <></>
}

export default withSearch<ContainerProps, ContainerContext>(
  ({ setSort, clearFilters, setSearchTerm, ...rest }) => ({
    setSort,
    setSearchTerm,
    clearFilters,
    ...rest
  })
)(InitialFilterControl)
