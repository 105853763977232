import dynamic from 'next/dynamic'
import React from 'react'

import { Theme, useMediaQuery } from '@mui/material'

import Box from 'app/components/base/Box'
import Stack from 'app/components/base/Stack'
import InitialFilterControl from 'app/features/SearchFeature/components/InitialFilterControl'
import SearchAppProvider, {
  DISJUNCTIVE_FACETS,
  FACETS,
  RESULT_FIELDS,
  SEARCH_FIELDS
} from 'app/features/SearchFeature/providers/SearchProvider'
import SearchAppResult from 'app/features/SearchFeature/SearchResult'

import FilterPanel from './FilterPanel'
import HomeViewContainer from './HomeViewContainer'

const FilterSubpanel = dynamic(
  () => import('~/views/HomeView/components/FilterSubpanel'),
  {
    ssr: false
  }
)

const BoxLicenseInformation = dynamic(
  () => import('~/views/HomeView/components/BoxLicenseInformation'),
  {
    ssr: false
  }
)

const MatchMeSearchBlock = dynamic(
  () => import('app/features/Profile/MatchMeFeature/MatchMeSearchBlock'),
  {
    ssr: false
  }
)
const SearchPagination = dynamic(
  () => import('app/features/SearchFeature/components/SearchPagination'),
  {
    ssr: false
  }
)

const ActiveFilters = dynamic(
  () => import('app/features/HomePageFeature/ChipFeature/ActiveFilters')
)

const SearchSection = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <SearchAppProvider
      searchQuery={{
        facets: FACETS,
        disjunctiveFacets: DISJUNCTIVE_FACETS,
        search_fields: SEARCH_FIELDS,
        result_fields: RESULT_FIELDS
      }}
    >
      <Stack sx={{ overflowX: 'hidden' }}>
        <Box
          sx={{
            ...(!isMobile && { boxShadow: '0px 12px 24px -4px #919EAB26' }),
            mb: { xs: 2, md: 2.25 }
          }}
        >
          <HomeViewContainer>
            <InitialFilterControl />

            <FilterPanel />
          </HomeViewContainer>
        </Box>

        {!isMobile && (
          <HomeViewContainer>
            <FilterSubpanel />
          </HomeViewContainer>
        )}

        <Stack spacing={2} sx={{ display: { md: 'none' } }}>
          {isMobile && <ActiveFilters />}

          <HomeViewContainer>
            <BoxLicenseInformation />
          </HomeViewContainer>
        </Stack>

        <HomeViewContainer>
          <MatchMeSearchBlock />
        </HomeViewContainer>

        <HomeViewContainer>
          <Stack spacing={{ xs: 3.75, sm: 5 }}>
            <SearchAppResult />

            <SearchPagination />
          </Stack>
        </HomeViewContainer>
      </Stack>
    </SearchAppProvider>
  )
}

export default SearchSection
