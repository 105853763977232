import React from 'react'

import { FormattedMessage } from 'react-intl'

import useVerifyAuth from 'app/features/ListingFeature/hooks/useVerifyAuth'
import useBoolean from 'app/hooks/useBoolean'

import Button, { ButtonProps } from '../base/Button'
import MyGarageDialog from '../dialogs/MyGarageDialog'

interface MyGarageButtonProps extends Partial<ButtonProps> {
  onConfirm?: (vehicle: MyGarageVehicleModel) => void
}

const MyGarageButton = ({
  onConfirm = () => {},
  children,
  ...props
}: MyGarageButtonProps) => {
  const [openDialog, handleDialog] = useBoolean(false)
  const checkAuth = useVerifyAuth()

  return (
    <>
      <Button
        variant="contained"
        size="small"
        {...props}
        onClick={() => {
          checkAuth(handleDialog.on)
        }}
      >
        {children || <FormattedMessage defaultMessage="My Garage" />}
      </Button>

      {openDialog && (
        <MyGarageDialog
          open={openDialog}
          onClose={handleDialog.off}
          onConfirm={onConfirm}
        />
      )}
    </>
  )
}

export default MyGarageButton
