import { useCallback, useMemo, useState } from 'react'

type UseBooleanReturn = [
  boolean,
  {
    toggle: () => void
    on: () => void
    off: () => void
  }
]

const useBoolean = (initialValue = false): UseBooleanReturn => {
  const [value, setValue] = useState<boolean>(initialValue)

  const toggle = useCallback(() => setValue((oldValue) => !oldValue), [])

  const handlers = useMemo(
    () => ({
      toggle,
      on: () => setValue(true),
      off: () => setValue(false)
    }),
    [toggle]
  )

  return [value, handlers]
}

export default useBoolean
