import { useIntl } from 'react-intl'
import { Link } from 'solito/link'

import LocationSVG from 'app/assets/icons/listing/location-gradient.svg'
import TradePriceIcon from 'app/assets/icons/profile/trade-price-icon.svg'
import AspectRatio from 'app/components/base/AspectRatio'
import Box from 'app/components/base/Box'
import Image from 'app/components/base/Image'
import Paper from 'app/components/base/Paper'
import Skeleton from 'app/components/base/Skeleton'
import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'
import LikeButton from 'app/components/buttons/LikeButton'
import { auctionReserveText } from 'app/utils/constants/auction.constants'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import { defaultCurrency } from 'app/utils/constants/intl.constants'
import { setDefaultFontSizeAtSystemScale } from 'app/utils/helpers/scaling.helpers'

import AuctionClosesLabel from './components/AuctionClosesLabel'
import { ReservedLabel, SoldOutLabel } from './components/CardDynamicComponents'

interface ProductCardProps {
  index?: number
  link: string
  location: string
  image: string
  title: string
  partNumber: string
  price: number
  currency: string
  auctionEndDate: string
  loading?: boolean
  id?: string
  isLiked?: boolean
  auctionReserveStatus?: string
  listingUserId?: string
  isSold?: boolean
  isReserved?: boolean
  isBusinessUser?: boolean
  isTradePricing?: boolean
  subTitle?: string
}

const priority = [0, 1, 2, 3, 4, 5]

const ProductCard = ({
  currency = defaultCurrency,
  image,
  location,
  partNumber,
  price,
  title,
  subTitle,
  loading,
  auctionEndDate,
  link,
  id,
  auctionReserveStatus,
  isLiked = false,
  isSold = false,
  listingUserId,
  isReserved,
  index,
  isBusinessUser = false,
  isTradePricing = false
}: ProductCardProps) => {
  const { formatMessage, formatNumber } = useIntl()

  const priceWithCurrency = formatNumber(price, { currency, style: 'currency' })

  const isAuction = !!auctionEndDate && !isReserved && !isSold
  const auctionReserveMessage = !!auctionReserveStatus
    ? auctionReserveText[auctionReserveStatus]
    : auctionReserveText.no_reserve

  const changeColorPrice = isTradePricing && isBusinessUser
  const isPriorityImage = index !== undefined

  return (
    <Paper
      data-testid="product-card"
      sx={{
        position: 'relative',
        zIndex: 0,
        p: 0,
        borderRadius: '16px',
        ...(!IS_NATIVE_APP && { height: '100%' })
      }}
    >
      <Stack sx={{ borderRadius: '16px', overflow: 'hidden' }}>
        <Link href={link} prefetch={false} aria-label="product-card">
          <Box sx={{ position: 'relative' }}>
            <AspectRatio ratio={1 / 1}>
              <Skeleton loading={loading} variant="rectangular" height="100%">
                <Box
                  sx={{ position: 'relative', width: '100%', height: '100%' }}
                >
                  <Image
                    src={image}
                    alt={title}
                    layout="fill"
                    sizes={'200px'}
                    {...(isPriorityImage && {
                      priority: priority.includes(index)
                    })}
                  />
                </Box>
              </Skeleton>
            </AspectRatio>

            <Stack
              sx={{
                position: 'absolute',
                left: '10px',
                right: '10px',
                bottom: '10px'
              }}
            >
              {isSold && !loading && <SoldOutLabel />}

              {isReserved && !loading && <ReservedLabel />}

              {isAuction && !loading && (
                <AuctionClosesLabel auctionEndDate={auctionEndDate} />
              )}
            </Stack>
          </Box>
        </Link>

        <Stack sx={{ p: 1.5, paddingTop: 1 }}>
          <Stack direction="column" spacing={0.25} sx={{ height: 46 }}>
            <Skeleton loading={loading} width="100%">
              <Link
                href={link}
                prefetch={false}
                aria-label="product-card-title"
              >
                <Text
                  lineClamp={1}
                  variant="h5"
                  sx={{
                    fontWeight: 500,
                    height: 20,
                    ...(!IS_NATIVE_APP && {
                      wordBreak: 'break-word',
                      '&:hover': { color: 'secondary.dark' }
                    })
                  }}
                >
                  {title}
                </Text>
              </Link>
            </Skeleton>

            <Skeleton loading={loading} width="100%">
              {!!subTitle && (
                <Text
                  lineClamp={2}
                  variant="subtitle2"
                  sx={{
                    height: 26,
                    lineHeight: IS_NATIVE_APP
                      ? setDefaultFontSizeAtSystemScale(13)
                      : '13px',
                    ...(!IS_NATIVE_APP && { wordBreak: 'break-word' })
                  }}
                >
                  {subTitle}
                </Text>
              )}
            </Skeleton>
          </Stack>

          <Skeleton loading={loading} width="30%">
            <Text
              variant="subtitle2"
              color="text.third"
              lineClamp={1}
              sx={{ height: 18, mt: 0.25 }}
            >
              {partNumber}
            </Text>
          </Skeleton>

          <Skeleton loading={loading} width="80%">
            <Stack
              direction="row"
              spacing={1}
              sx={{ alignItems: 'center', my: '5px' }}
            >
              <LocationSVG />

              <Text
                variant="subtitle1"
                lineClamp={1}
                color="text.third"
                {...(IS_NATIVE_APP && { sx: { flex: 1, flexWrap: 'wrap' } })}
              >
                {location}
              </Text>
            </Stack>
          </Skeleton>

          <Skeleton loading={loading} width="40%">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text
                variant="h3"
                lineClamp={1}
                sx={{
                  fontWeight: 500,
                  color: changeColorPrice ? 'secondary.third' : 'text.primary',
                  ...(IS_NATIVE_APP && { maxWidth: '90%' })
                }}
              >
                {priceWithCurrency}
              </Text>

              {isTradePricing && <TradePriceIcon />}
            </Stack>
          </Skeleton>

          <Skeleton loading={loading} width="20%">
            <Text variant="subtitle2" color="text.third">
              {!!auctionEndDate && formatMessage(auctionReserveMessage)}
            </Text>
          </Skeleton>
        </Stack>

        {!loading && (
          <LikeButton
            listingUserId={listingUserId}
            listingId={String(id)}
            isLiked={isLiked}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 100,
              backgroundColor: 'background.default',
              display: 'flex',
              height: 30,
              width: 30,
              p: 0
            }}
          />
        )}
      </Stack>
    </Paper>
  )
}

export default ProductCard
