import { useState, useEffect, useCallback } from 'react'

interface UseToggleRequestProps {
  state: boolean
  onToggleRequest: () => Promise<any>
  onSuccess?: () => void
  onError?: (error: unknown) => void
}

const useToggleRequest = ({
  state,
  onToggleRequest,
  onSuccess,
  onError
}: UseToggleRequestProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsActive(state)
  }, [state])

  const handleRequest = useCallback(async () => {
    try {
      setIsActive((prev) => !prev)

      setIsLoading(true)
      await onToggleRequest()
      setIsLoading(false)

      if (onSuccess) onSuccess()
    } catch (e: any) {
      setIsActive((prev) => !prev)
      setIsLoading(false)

      if (onError) onError(e)
    }
  }, [onToggleRequest])

  return {
    toggle: handleRequest,
    state: isActive,
    isLoading
  }
}

export default useToggleRequest
