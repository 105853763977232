import { Platform } from 'react-native'

import { FormattedDate, FormattedMessage } from 'react-intl'

import Text from 'app/components/base/Text'
import { setDefaultFontSizeAtSystemScale } from 'app/utils/helpers/scaling.helpers'

import { ChipLabel } from './CardDynamicComponents'

interface AuctionClosesLabelProps {
  auctionEndDate: string
}

const AuctionClosesLabel = ({ auctionEndDate }: AuctionClosesLabelProps) => {
  return (
    <ChipLabel sx={{ px: 1 }}>
      <Text
        variant="subtitle2"
        sx={{
          fontWeight: 500,
          ...Platform.select({
            web: { lineHeight: '22px' },
            native: {
              fontSize: setDefaultFontSizeAtSystemScale(11),
              lineHeight: setDefaultFontSizeAtSystemScale(22)
            }
          })
        }}
      >
        <FormattedMessage defaultMessage="Closes:" />{' '}
        <FormattedDate
          value={auctionEndDate}
          day="numeric"
          month="short"
          weekday="short"
        />
      </Text>
    </ChipLabel>
  )
}

export default AuctionClosesLabel
