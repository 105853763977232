import React from 'react'

import { Grid, GridProps } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'

import { isOfType } from 'app/utils/helpers/type.helpers'

type GridListProps = {
  items: any[]
  spacing?: GridProps['spacing']
  columns?: ResponsiveStyleValue<number>
  renderItem: (item: any, index: number) => React.ReactNode
  keyExtractor?: (item: any) => string | number
}

const maxColumns = 12

const getColumns = (columns: GridListProps['columns']) => {
  if (isOfType.object(columns)) {
    return Object.entries(columns).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: maxColumns / (value || 0) }),
      {}
    )
  }

  return { xs: maxColumns / (columns || 0) }
}

const GridList = ({
  items,
  spacing = 0,
  columns = 1,
  keyExtractor = (item) => item.id,
  renderItem
}: GridListProps) => {
  const gridColumns = getColumns(columns)

  return (
    <Grid container spacing={spacing}>
      {items?.map((item, index) => (
        <Grid item {...gridColumns} key={keyExtractor(item)}>
          {renderItem(item, index)}
        </Grid>
      ))}
    </Grid>
  )
}

export default React.memo(GridList)
