import React from 'react'

import Select, { SelectProps } from 'app/components/base/Select'
import useFormField, { UseFormFieldProps } from 'app/hooks/useFormField'
import { combineControllerProps } from 'app/utils/helpers/form.helpers'

import FormControl, { FormControlProps } from '../base/FormControl'

export interface TextFieldCustomProps extends UseFormFieldProps {
  formControlProps?: FormControlProps
  withFilter?: boolean
  textOption?: string
  sortOption?: boolean
  isUniversalOption?: boolean
}

type SelectFieldProps = Omit<SelectProps, 'value' | 'onChange'> &
  TextFieldCustomProps & {}

const SelectField = ({
  formControlProps,
  optionSettings,
  helperText,
  ...props
}: SelectFieldProps) => {
  const { controllerProps, ...fieldProps } = combineControllerProps(props)
  const { field, error, onChange, value } = useFormField(controllerProps)

  return (
    <FormControl
      errorMessage={error}
      helperText={helperText}
      {...formControlProps}
    >
      <Select
        {...field}
        {...fieldProps}
        optionSettings={{
          key: field.name,
          ...optionSettings
        }}
        error={!!error}
        onChange={onChange}
        value={value}
      />
    </FormControl>
  )
}

export default SelectField
