import { Link, SxProps } from '@mui/material'

import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'

const commonStyles = { fontSize: 10, lineHeight: '14px' } as SxProps

const GooglePolicyTerms = () => {
  return (
    <Stack direction="column" sx={{ maxWidth: 358, textAlign: 'center' }}>
      <Text variant="subtitle2" color="text.third" sx={commonStyles}>
        Protected by reCAPTCHA <br />
        Google{' '}
        <Link
          href="https://policies.google.com/privacy"
          target="_blank"
          color="primary.dark"
          sx={commonStyles}
        >
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link
          href="https://policies.google.com/terms"
          target="_blank"
          color="primary.dark"
          sx={commonStyles}
        >
          Terms and Conditions
        </Link>{' '}
        apply
      </Text>
    </Stack>
  )
}

export default GooglePolicyTerms
