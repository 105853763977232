import { Dimensions } from 'react-native'

const { fontScale } = Dimensions.get('window')

// at the system scale fontSize we change to the default state
export const setDefaultFontSizeAtSystemScale = (size: number) => {
  if (fontScale === 1) return size

  return Math.ceil(size / fontScale)
}
