import { lazy, mixed, number, object, string } from 'yup'

import { SEGMENT_CATEGORY_ATTRIBUTE_KEY } from 'app/utils/constants/elastic.constants'
import { isOfType } from 'app/utils/helpers/type.helpers'

const transformToArray = (value) => (isOfType.string(value) ? [value] : value)

export const SearchSchema = lazy((values) => {
  const AdditionalAttributesSearchSchema = Object.keys(values).reduce(
    (acc, key) => {
      if (!key.includes(SEGMENT_CATEGORY_ATTRIBUTE_KEY)) return acc

      return acc.shape({
        [key]: mixed().transform(transformToArray).optional()
      })
    },
    object()
  )

  return AdditionalAttributesSearchSchema.shape({
    q: string().optional(),
    p: number().optional(),
    year: mixed().transform(transformToArray).optional(),
    makes: mixed().transform(transformToArray).optional(),
    models: mixed().transform(transformToArray).optional(),
    category_id: mixed().transform(transformToArray).optional(),
    part_number: mixed().transform(transformToArray).optional(),
    shipping_types: mixed().transform(transformToArray).optional(),
    sub_category_id: mixed().transform(transformToArray).optional(),
    'price-range': mixed().transform(transformToArray).optional(),
    condition: mixed().transform(transformToArray).optional(),
    warranty: mixed().transform(transformToArray).optional(),
    item_type: mixed().transform(transformToArray).optional(),
    order: mixed().transform(transformToArray).optional(),
    sort: mixed().transform(transformToArray).optional(),
    country: mixed().transform(transformToArray).optional(),
    full_country_name: mixed().transform(transformToArray).optional(),
    city: mixed().transform(transformToArray).optional(),
    trim_id: mixed().transform(transformToArray).optional(),
    serie_id: mixed().transform(transformToArray).optional(),
    generation_id: mixed().transform(transformToArray).optional(),
    international_shipping: mixed().transform(transformToArray).optional()
  })
})
