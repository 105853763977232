import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'

import debounce from 'lodash.debounce'

import Slider, { SliderProps } from 'app/components/base/Slider/Slider'
import Stack, { StackProps } from 'app/components/base/Stack'
import TextInputNumber, {
  TextInputNumberProps
} from 'app/components/base/TextInputNumber'
import useEffectAfterMount from 'app/hooks/useEffectAfterMount'

import Box from './Box'

export type SliderRangeProps = Omit<StackProps, 'onChange'> & {
  value: readonly [number, number] | null
  onChange: (value: [number, number]) => void
  min?: number
  max?: number
  sliderProps?: Partial<Omit<SliderProps, 'value' | 'onChange' | 'min' | 'max'>>
  inputNumberProps?: Partial<Omit<TextInputNumberProps, 'value' | 'onChange'>>
  inputStackProps?: StackProps
}

const SliderRange = ({
  value,
  onChange,
  min = 0,
  max = 0,
  sliderProps,
  inputNumberProps,
  inputStackProps,
  ...rest
}: SliderRangeProps) => {
  const valueRef = useRef(value || [])
  const isValidRef = useRef(false)
  const [localValue, setLocalValue] = useState<[string, string]>([
    String(min),
    String(max)
  ])
  const [localMinValue, localMaxValue] = [localValue[0], localValue[1]]
  const [minValue = min, maxValue = max] = value || []

  const isWeb = Platform.OS === 'web'
  const validLocalMinValue = isWeb ? Number(localMinValue) : localMinValue
  const validLocalMaxValue = isWeb ? Number(localMaxValue) : localMaxValue

  const isValidMin = +localMinValue >= min
  const isMaxBiggerThanMin = +localMinValue <= +localMaxValue
  const isSetLocalMinMax = (localMinValue && localMaxValue) !== ''
  const isLocalMinMaxNotEqual = +localMinValue !== +localMaxValue

  isValidRef.current =
    isMaxBiggerThanMin &&
    isSetLocalMinMax &&
    isValidMin &&
    isLocalMinMaxNotEqual
  valueRef.current = [minValue, maxValue]

  const { width } = useWindowDimensions()

  const handleChange = useMemo(
    () =>
      debounce((newValue) => {
        const [newMinValue, newMaxValue] = newValue || []
        const isEqual =
          +newMinValue === valueRef.current[0] &&
          +newMaxValue === valueRef.current[1]

        if (!isEqual && isValidRef.current) {
          onChange([+newMinValue, +newMaxValue])
        }
      }, 300),
    []
  )

  useEffectAfterMount(() => {
    if (isValidRef.current) {
      handleChange(localValue)
    }
  }, [localMinValue, localMaxValue])

  useEffect(() => {
    const [newMinValue, newMaxValue] = localValue || []

    if (value && value?.length) {
      const isEqual = +newMinValue === minValue && +newMaxValue === maxValue
      if (!isEqual) setLocalValue([String(value[0]), String(value[1])])
    } else {
      setLocalValue([String(min), String(max)])
    }
  }, [minValue, maxValue])

  const changeInputHandler = ({
    name,
    value
  }: {
    name: 'min' | 'max'
    value: string
  }) => {
    const normalized = +value < min ? value : +value > max ? String(max) : value
    const fieldValue = Number.isNaN(normalized)
      ? String({ min, max }[name])
      : normalized

    setLocalValue((state) => {
      const minPrice = name === 'min' ? fieldValue : state[0]
      const maxPrice = name === 'max' ? fieldValue : state[1]

      return [minPrice, maxPrice]
    })
  }

  const paddingX = 52

  return (
    <Stack {...rest}>
      <Box sx={{ px: 1, width: '100%' }}>
        <Slider
          value={[+localMinValue || min, +localMaxValue || max]}
          onChange={(v) => setLocalValue([String(v[0]), String(v[1])])}
          min={min}
          max={max}
          {...Platform.select({
            native: {
              trackWidth: width - paddingX
            }
          })}
          {...sliderProps}
        />
      </Box>

      <Stack direction="row" {...inputStackProps}>
        <TextInputNumber
          value={validLocalMinValue}
          variant="number"
          onChange={(e) => {
            changeInputHandler({ value: e?.target?.value ?? e, name: 'min' })
          }}
          error={!isValidRef.current}
          inputProps={{
            min: min,
            max: +localMaxValue,
            inputMode: 'decimal'
          }}
          numberFormat={numberFormat}
          {...Platform.select({
            native: {
              sx: {
                flex: 1
              }
            }
          })}
          {...inputNumberProps}
        />

        <TextInputNumber
          value={validLocalMaxValue}
          variant="number"
          onChange={(e) => {
            changeInputHandler({ value: e?.target?.value ?? e, name: 'max' })
          }}
          error={!isValidRef.current}
          inputProps={{
            min: +localMinValue,
            max: max,
            inputMode: 'decimal'
          }}
          numberFormat={numberFormat}
          {...Platform.select({
            native: {
              sx: {
                flex: 1
              }
            }
          })}
          {...inputNumberProps}
        />
      </Stack>
    </Stack>
  )
}

const numberFormat = {
  thousandSeparator: true,
  decimalSeparator: '.',
  decimalScale: 2,
  allowedDecimalSeparators: ['.']
}

export default SliderRange
