import { bindHover } from 'material-ui-popup-state/core'
import { usePopupState } from 'material-ui-popup-state/hooks'
import { useIntl } from 'react-intl'

import { Theme, useMediaQuery } from '@mui/material'

import Box from 'app/components/base/Box'
import SaveSearchButton from 'app/components/buttons/SaveSearchButton'
import Popper from 'app/components/common/Popover'

const BookmarkInButton: RFC = () => {
  const intl = useIntl()

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'savedSearchPopoverId'
  })
  const isNotLargeDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  )

  return (
    <Box>
      <SaveSearchButton
        size="small"
        variant="text"
        sx={{
          minWidth: { lg: 150, cmd: 140, md: 0 },
          flexDirection: 'row-reverse',

          '& .MuiButton-startIcon': {
            m: 0
          }
        }}
        labelProps={{
          sx: { display: { lg: 'flex', cmd: 'flex', md: 'none' }, mr: 1 }
        }}
        {...(isNotLargeDesktop && bindHover(popupState))}
      />

      {popupState.isOpen && (
        <Popper
          placement="bottom"
          text={intl.formatMessage({ defaultMessage: 'Save Search' })}
          popupState={popupState}
        />
      )}
    </Box>
  )
}

export default BookmarkInButton
