import React, { useEffect, useState } from 'react'

import { withSearch } from '@elastic/react-search-ui'
import { SearchContextState } from '@elastic/react-search-ui/lib/esm/withSearch'
import { useIntl } from 'react-intl'

import {
  Badge,
  ClickAwayListener,
  Collapse,
  Theme,
  useMediaQuery
} from '@mui/material'

import FilterIcon from 'app/assets/icons/search/white-filter.svg'
import Box from 'app/components/base/Box'
import Button from 'app/components/base/Button'
import Flex from 'app/components/base/Flex'
import Stack from 'app/components/base/Stack'
import MyGarageWithSearch from 'app/components/buttons/MyGarageWithSearch'
import SearchBox from 'app/features/SearchFeature/components/SearchBox'
import {
  CategoryFilter,
  CityFilter,
  ConditionFilter,
  CountryFilter,
  GenerationFilter,
  ItemTypeFilter,
  MakeFilter,
  ModelFilter,
  PartNumberFilter,
  PriceFilter,
  SeriesFilter,
  ShippingTypeFilter,
  SubCategoryFilter,
  TrimFilter,
  WarrantyFilter
} from 'app/features/SearchFeature/components/SearchFilters'
import SearchLocation from 'app/features/SearchFeature/components/SearchLocation'
import SearchSorting from 'app/features/SearchFeature/components/SearchSorting'
import ClearAllFilterButton from 'app/libs/elastic/filters/ClearAllFilterButton'

import BookmarkInButton from '~/components/buttons/BookmarkInButton'

import SearchViewContainer from '../HomeViewContainer'

const selector = ({ filters, sortField, searchTerm }: SearchContextState) => ({
  filters,
  sortField,
  searchTerm
})

const FilterPanelDesktop = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'block' },
        position: 'relative',
        pb: 2.5
      }}
    >
      <Stack
        direction="row"
        sx={{ mb: 3.75, alignItems: 'center', justifyContent: 'space-between' }}
        spacing={2}
      >
        <SearchBox size="large" />

        <Stack
          direction="row"
          spacing={1.5}
          sx={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <BookmarkInButton />

          <SearchLocation />

          <MyGarageWithSearch sx={{ width: 110 }} />

          <SearchSorting sx={{ width: 110 }} size="small" />

          <CollapseFilter />
        </Stack>
      </Stack>

      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          display: { xs: 'none', md: 'flex' }
        }}
      >
        <Stack direction="row" flexWrap="wrap" sx={{ gap: 1.25 }}>
          <PartNumberFilter sx={{ width: 175 }} />

          {isDesktop && <MakeFilter sx={{ width: 175 }} />}

          <ModelFilter sx={{ width: 175 }} />

          <GenerationFilter sx={{ width: 175 }} />

          <SeriesFilter sx={{ width: 175 }} />

          <TrimFilter sx={{ width: 175 }} divider />
        </Stack>
      </Flex>
    </Box>
  )
}

const EndIcon = withSearch(selector)(({ filters }) => {
  const [isFilters, setIsFilters] = useState(false)

  useEffect(() => {
    setIsFilters(!!filters.length)
  }, [filters])

  return (
    <Badge
      color="secondary"
      variant="dot"
      invisible={!isFilters}
      sx={{
        '.MuiBadge-badge': {
          top: 6,
          right: 2,
          minWidth: 14,
          height: 14
        }
      }}
    >
      <FilterIcon />
    </Badge>
  )
})

const CollapseFilter: RFC = () => {
  const [filterIsOpen, setFilterState] = useState(false)
  const intl = useIntl()
  const isNotLargeDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  )

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          if (filterIsOpen) {
            //* Check for open Popover and, if so, do not close Collapse
            const popover = document.getElementsByClassName('MuiPopover-root')

            if (!popover.length) {
              setFilterState(false)
            }
          }
        }}
      >
        <Box>
          <Button
            size="small"
            variant="contained"
            endIcon={<EndIcon />}
            sx={{
              width: 120,
              backgroundColor: 'primary.main',
              color: 'common.white'
            }}
            onClick={() => setFilterState((prev) => !prev)}
          >
            {intl.formatMessage({ defaultMessage: 'All Filters' })}
          </Button>

          <Collapse
            timeout={100}
            mountOnEnter
            in={filterIsOpen}
            sx={{
              top: '100%',
              zIndex: 100,
              left: '50%',
              right: '50%',
              width: '100vw',
              marginLeft: '-50vw',
              marginRight: `-50vw`,
              position: 'absolute',
              display: { xs: 'none', md: 'block' },
              boxShadow: '0px 193px 100px 20px rgba(145, 158, 171, 0.15)'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background.default',
                pb: { md: 1, lg: 0 }
              }}
            >
              <SearchViewContainer sx={{ position: 'relative', pb: 1 }}>
                <Stack spacing={{ md: 2, lg: 0.3 }}>
                  <Stack direction="row" spacing={1.25}>
                    <CategoryFilter sx={{ width: 175 }} fixedPaperWidth />

                    <SubCategoryFilter sx={{ width: 175 }} fixedPaperWidth />

                    <ShippingTypeFilter sx={{ width: 175 }} />

                    {!isNotLargeDesktop && (
                      <PriceFilter
                        sliderProps={{
                          sx: { mr: 3, maxWidth: 300, minWidth: 200 }
                        }}
                        inputStackProps={{ spacing: '5px' }}
                        inputNumberProps={{ sx: { width: 120 } }}
                      />
                    )}
                  </Stack>

                  {isNotLargeDesktop && (
                    <PriceFilter
                      sliderProps={{
                        sx: { mr: 3, maxWidth: 300, minWidth: 200 }
                      }}
                      inputStackProps={{ spacing: '5px' }}
                      inputNumberProps={{ sx: { width: 120 } }}
                    />
                  )}
                  <Stack
                    direction="row"
                    spacing={{ md: 0, lg: 2 }}
                    sx={{ alignItems: 'flex-start' }}
                  >
                    <Stack
                      direction={{ xs: 'column', md: 'row', lg: 'column' }}
                      spacing={{ xs: 0, md: 0, lg: 0 }}
                      sx={{ mt: { md: 0, lg: '16px !important' }, pb: 5 }}
                    >
                      <Stack direction="row" spacing={1.25}>
                        <CountryFilter sx={{ width: 175 }} />

                        <CityFilter sx={{ width: 175 }} />
                      </Stack>
                      {
                        //TODO: return international shipping after international release
                      }
                      {/* <InternationalShippingFilter /> */}
                    </Stack>

                    {!isNotLargeDesktop && (
                      <Stack direction="row" spacing={2}>
                        <ConditionFilter sx={{ width: 110 }} />

                        <WarrantyFilter sx={{ width: 110 }} />

                        <ItemTypeFilter sx={{ width: 110 }} />
                      </Stack>
                    )}
                  </Stack>

                  {isNotLargeDesktop && (
                    <Stack direction="row" spacing={2}>
                      <ConditionFilter sx={{ width: 110 }} />

                      <WarrantyFilter sx={{ width: 110 }} />

                      <ItemTypeFilter sx={{ width: 110 }} />
                    </Stack>
                  )}
                </Stack>

                <Flex
                  sx={{
                    position: 'absolute',
                    justifyContent: 'flex-end',
                    mb: 0.75,
                    right: { md: 0, lg: 45 },
                    bottom: { md: 2, lg: 0 }
                  }}
                >
                  <ClearAllFilterButton />
                </Flex>
              </SearchViewContainer>
            </Box>
          </Collapse>
        </Box>
      </ClickAwayListener>
    </>
  )
}

export default FilterPanelDesktop
