import React, { useMemo } from 'react'

import { Sorting } from '@elastic/react-search-ui'
import { useIntl } from 'react-intl'

import Select, { SelectProps } from 'app/components/base/Select'

import { searchSortOptions } from '../utils/search-feature.options'

type SearchSortingProps = Partial<Omit<SelectProps, 'onChange' | 'value'>> & {
  size?: 'large' | 'small'
}

const SearchSorting = (props: SearchSortingProps) => {
  const intl = useIntl()

  const sortOptions = useMemo(
    () =>
      searchSortOptions.map((option) => {
        const name = intl.formatMessage(option.name)
        return { ...option, name }
      }),
    [intl.locale]
  )

  return (
    <Sorting
      sortOptions={sortOptions}
      view={({ onChange, options, value }) => {
        return (
          <Select
            variant="button"
            label={intl.formatMessage({ defaultMessage: 'Sort By' })}
            value={value}
            onChange={(v) => {
              if (v) {
                onChange(v)
              }
            }}
            renderValue={(option) => {
              const selectedOption = options?.find(
                (item) => item?.value === option?.value || option
              )

              const label =
                selectedOption?.value === options[0]?.value
                  ? intl.formatMessage({ defaultMessage: 'Sort By' })
                  : selectedOption?.label

              return label || ''
            }}
            optionSettings={{
              options,
              optionLabel: 'label',
              optionValue: 'value'
            }}
            {...props}
          />
        )
      }}
    />
  )
}

export default SearchSorting
