import React from 'react'

import ProductCard from 'app/components/cards/ProductCard'
import useAppStore from 'app/store/app.store'
import useAuthStore, { getAuthUser } from 'app/store/auth.store'
import { USER_ROLE } from 'app/utils/constants/role.constants'
import { LISTING_STATUS } from 'app/utils/constants/status.constants'

interface ListItemProps {
  item: ResultFields
  loading: boolean
  isLiked: boolean
  index: number
}

const ListItem = ({ item, loading, isLiked, index }: ListItemProps) => {
  const currency = useAppStore((state) => state.currency)
  const { role } = useAuthStore(getAuthUser)
  const isBusinessUser = role === USER_ROLE.BUSINESS

  const newItem = item || {}
  const slug = newItem.slug?.raw
  const link = `/listing/${slug}`
  const isReserved = (newItem.status?.raw as string) === LISTING_STATUS.reserved
  const price = isBusinessUser
    ? item?.[`${currency}_trade_price`]?.raw
    : item?.[`${currency}_price`]?.raw
  const isTradePricing = !!item?.trade_pricing?.raw

  return (
    <ProductCard
      index={index}
      title={item.title?.raw}
      subTitle={item.subtitle?.raw}
      image={item.attachment_url?.raw}
      currency={currency}
      price={price}
      location={item.city?.raw}
      partNumber={item.part_number?.raw}
      link={link}
      loading={loading}
      id={item.id?.raw}
      isLiked={isLiked}
      listingUserId={item?.seller_id?.raw}
      auctionEndDate={item?.auction_close_date?.raw}
      auctionReserveStatus={item?.auction_reserve_status?.raw}
      isReserved={isReserved}
      isBusinessUser={isBusinessUser}
      isTradePricing={isTradePricing}
    />
  )
}

export default ListItem
