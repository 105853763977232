import { Platform } from 'react-native'

import { FormattedMessage } from 'react-intl'
import { useRouter } from 'solito/router'

import IconsSVG from 'app/assets/icons/homePage/home-help-center-icons.svg'
import Box from 'app/components/base/Box'
import Button from 'app/components/base/Button'
import Stack from 'app/components/base/Stack'
import Text, { TextProps } from 'app/components/base/Text'
import useOpenExternalLink from 'app/hooks/useOpenExternalLink'
import { IS_NATIVE_APP, WEBSITE_URL } from 'app/utils/constants/env.constants'

const textStyles = {
  color: 'common.white',
  ...Platform.select({
    web: {
      fontSize: { xs: 28, sm: 48 },
      lineHeight: { xs: '48px', sm: '65px' }
    },
    native: { fontSize: 28, lineHeight: 48 }
  })
} as TextProps['sx']

const NeedHelp = () => {
  const { push } = useRouter()
  const { openLink } = useOpenExternalLink()
  const link = '/help-center'

  const handleClick = () => {
    if (IS_NATIVE_APP) {
      openLink({ link: `${WEBSITE_URL}/${link}`, title: 'Help Center' })
      return
    }

    push(link)
  }

  return (
    <Box
      sx={{
        py: { xs: 6, md: 8.75 },
        backgroundColor: 'primary.main',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        overflowX: 'hidden'
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{ px: { xs: 2, md: 5 }, width: { xs: '100%', lg: 1200 } }}
      >
        <Stack direction="column" sx={{ color: 'background.default' }}>
          <Text variant="h1" sx={{ ...textStyles }}>
            <FormattedMessage defaultMessage="Need help?" />
          </Text>

          <Text variant="h2" sx={{ ...textStyles }}>
            <FormattedMessage defaultMessage="Check out our help center" />
          </Text>
        </Stack>

        <Button
          aria-label="go to help center"
          variant="contained"
          color="secondary"
          sx={{
            width: 190,
            color: 'primary.main',
            ...Platform.select({ native: { flex: 1 } })
          }}
          onClick={handleClick}
        >
          <FormattedMessage defaultMessage="Go to Help Center" />
        </Button>
      </Stack>

      <Box
        sx={{
          display: { xs: 'none', lg: 'block' },
          position: 'absolute',
          top: '50%',
          right: { lg: 40, xl: 120, xxl: 240 },
          ...(!IS_NATIVE_APP && { transform: 'translateY(-50%)' })
        }}
      >
        <IconsSVG />
      </Box>
    </Box>
  )
}

export default NeedHelp
